export const style = {
  inlineContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dataContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  statusActif: {
    padding: 4,
    width: 100,
    maxWidth: 100,
    fontSize: 11,
    fontWeight: "bold",
    borderRadius: 10,
    textAlign: "center",
    color: "#13c2c2",
    backgroundColor: "#e6fffb",
    borderColor: "#87e8de",
    borderWidth: 1,
    borderStyle: "solid",
  },
  statusInactif: {
    padding: 4,
    width: 80,
    fontSize: 11,
    fontWeight: "bold",
    borderRadius: 10,
    textAlign: "center",
    color: "#fa541c",
    backgroundColor: "#fff2e8",
    borderColor: " #ffbb96",
    borderWidth: 1,
    borderStyle: "solid",
  },
  headerCard: {
    borderRadius: "10px",
    background: "#fdfdfd",
    // boxShadow: "-3px 3px 30px #cfcfcf,6px -6px 38px #ffffff",
    boxShadow: "-3px 3px 10px #cfcfcf,6px -6px 10px #ffffff",
    width: "100%",
    margin: 0,
    height: "5em",
    /* display: "flex",
    alignItems: "flex-start" */
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative", // To use absolute positioning for the center item
    padding: "0 1em",
  },
  
  leftItems: {
    display: "flex",
    alignItems: "center",
  },
  
  centerItem: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    justifyContent: "center",
    width: "45%", // Adjust as needed to ensure it doesn't overlap with other items
  },
  
  rightItems: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  
  headerButton: {
    marginRight: "10px",
    marginTop: "-10px",
  },
  
  headerInput: {
    width: "80%",
    marginTop: "-10px",
    marginRight: "0.5em",
  },
  headerBreadcrumb: {
    margin: "-0.25em 1em 0 0",
    fontSize: "20px",
    fontWeight: 600,
    //float: "left",
  },
  headerButtonText: {
    fontSize: "1em",
  },
  tagItem: {
    padding: 4,
    width: 100,
    maxWidth: 100,
    fontSize: 12,
    fontWeight: "bold",
    borderRadius: 10,
    textAlign: "center",
    color: "#ffffff",
    backgroundColor: "#7e9dff",
    borderColor: "#ffffff",
    borderWidth: 1,
    borderStyle: "solid",
  },
};
