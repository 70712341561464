import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";

// Define the async thunk for fetching the data
export const getAllKiosks = createAsyncThunk(
  "kiosks/getAllKiosks",
  async () => {
    try {
      const response = await axios.get("/kiosks");
      return response.data;
    } catch (error) {
      const customError = {
        name: "Error fetching kiosks",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const addKiosk = createAsyncThunk("kiosks/addKiosk", async (data) => {
  try {
    const response = await axios.post("/kiosk", data);
    return response.data;
  } catch (error) {
    const customError = {
      name: "Error adding kiosk",
      message: error.response.data.message,
    };
    throw customError;
  }
});

export const updateKiosk = createAsyncThunk(
  "kiosks/updateKiosk",
  async (data) => {
    try {
      const response = await axios.put("/kiosk", data);
      return response.data;
    } catch (error) {
      const customError = {
        name: "Error updating kiosk",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const getKioskById = createAsyncThunk(
  "kiosks/getKioskById",
  async (kioskId) => {
    try {
      const response = await axios.get(`/kiosk/${kioskId}`);
      return response.data;
    } catch (error) {
      const customError = {
        name: "Error fetching kiosk",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const changeKioskStatus = createAsyncThunk(
  "kiosks/changeKioskStatus",
  async (data) => {
    try {
      const response = await axios.patch("/kiosk/change-status", data);
      return response.data;
    } catch (error) {
      const customError = {
        name: "Error changing kiosk status",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const kiosksSlice = createSlice({
  name: "Kiosk",
  initialState: {
    kiosks: { loading: false, kiosksList: [] },
    SelectedKiosk: { loading: false, kiosk: null },
  },
  reducers: {},
  extraReducers: {
    [getAllKiosks.pending]: (state) => {
      state.kiosks.loading = true;
    },
    [getAllKiosks.rejected]: (state, action) => {
      state.kiosks.loading = false;
    },
    [getAllKiosks.fulfilled]: (state, action) => {
      state.kiosks.kiosksList = action.payload;
      state.kiosks.loading = false;
    },
    [addKiosk.pending]: (state) => {
      state.kiosks.loading = true;
    },
    [addKiosk.rejected]: (state, action) => {
      state.kiosks.loading = false;
    },
    [addKiosk.fulfilled]: (state, action) => {
      state.kiosks.kiosksList.push(action.payload);
      state.kiosks.loading = false;
    },
    [updateKiosk.pending]: (state) => {
      state.kiosks.loading = true;
    },
    [updateKiosk.rejected]: (state, action) => {
      state.kiosks.loading = false;
    },
    [updateKiosk.fulfilled]: (state, action) => {
      state.kiosks.kiosksList = state.kiosks.kiosksList.map((kiosk) => {
        if (kiosk.id === action.payload.id) {
          return action.payload;
        }
        return kiosk;
      });
      state.kiosks.loading = false;
    },
    [getKioskById.pending]: (state) => {
      state.SelectedKiosk.loading = true;
    },
    [getKioskById.rejected]: (state, action) => {
      state.SelectedKiosk.loading = false;
    },
    [getKioskById.fulfilled]: (state, action) => {
      state.SelectedKiosk.kiosk = action.payload;
      state.SelectedKiosk.loading = false;
    },
    [changeKioskStatus.pending]: (state) => {
      state.kiosks.loading = true;
    },
    [changeKioskStatus.rejected]: (state, action) => {
      state.kiosks.loading = false;
    },
    [changeKioskStatus.fulfilled]: (state, action) => {
      state.kiosks.kiosksList = state.kiosks.kiosksList.map((kiosk) => {
        if (kiosk.id === action.payload.id) {
          return { ...kiosk, active: action.payload.active };
        }
        return kiosk;
      });
      state.kiosks.loading = false;
    },
  },
});

export default kiosksSlice.reducer;
