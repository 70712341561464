import React from "react";
import { useForm } from "antd/es/form/Form";
import { Form, Input, Select, Button } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getSpanStyleForHardware } from "../../Utils/Style/style-depending-on-role";

const HardwareForm = ({
  onSave,
  onSaveFailed,
  hardware,
  onCancel,
  onDirty,
  unsavedChanges,
}) => {
  const { t } = useTranslation();

  const hardwareName = [
    {
      value: "CARD_DISPENSER",
      label: t("hardwareNames.cardDispenser"),
      style: getSpanStyleForHardware("CARD_DISPENSER"),
    },
    {
      value: "PRINTER",
      label: t("hardwareNames.printer"),
      style: getSpanStyleForHardware("PRINTER"),
    },
    {
      value: "CASH_ACCEPTOR",
      label: t("hardwareNames.cashAcceptor"),
      style: getSpanStyleForHardware("CASH_ACCEPTOR"),
    },
    {
      value: "COIN_ACCEPTOR",
      label: t("hardwareNames.coinAcceptor"),
      style: getSpanStyleForHardware("COIN_ACCEPTOR"),
    },
    {
      value: "PAYMENT_MODULE",
      label: t("hardwareNames.paymentModule"),
      style: getSpanStyleForHardware("PAYMENT_MODULE"),
    },
    {
      value: "SERVER",
      label: t("hardwareNames.server"),
      style: getSpanStyleForHardware("SERVER"),
    },
  ];
  const [form] = useForm();

  const initialValues = {
    ...hardware,
    kioskId: hardware?.kiosk?.id,
  };

  const { kiosks } = useSelector((state) => state.kiosks);

  return (
    <Form
      form={form}
      name="hardware"
      layout="vertical"
      initialValues={initialValues}
      onFinish={onSave}
      onFinishFailed={onSaveFailed}
      autoComplete="off"
      onValuesChange={() => onDirty()}
    >
      <Form.Item
        label={t("hardwareScreen.form.hardwareName")}
        name="name"
        rules={[
          {
            required: true,
            message: t("hardwareScreen.form.pleaseEnterHardwareName"),
          },
        ]}
      >
        <Select size="medium" optionLabelProp="label">
          {hardwareName
            .filter((hardware) => hardware.value !== "SERVER")
            .map((hardwareName) => (
              <Select.Option
                key={hardwareName.value}
                value={hardwareName.value}
                label={hardwareName.label}
              >
                {hardwareName.label}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={t("hardwareScreen.form.hardwareSerialNumber")}
        name="serialNumber"
        rules={[
          {
            required: true,
            message: t("hardwareScreen.form.pleaseEnterHardwareSerialNumber"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("hardwareScreen.form.kiosk")}
        name="kioskId"
        rules={[
          {
            required: true,
            message: t("hardwareScreen.form.pleaseSelectKiosk"),
          },
        ]}
      >
        <Select size="medium" optionLabelProp="label">
          {kiosks.kiosksList?.map((kiosk) => (
            <Select.Option key={kiosk.id} value={kiosk.id} label={kiosk.name}>
              {kiosk.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item style={{ float: "right" }}>
        <Button
          type="default"
          style={{ marginBottom: 32, marginRight: 8 }}
          onClick={() => onCancel(unsavedChanges)}
        >
          {t("hardwareScreen.form.cancel")}
        </Button>
        <Button
          type="primary"
          style={{ marginBottom: 32 }}
          htmlType="submit"
          disabled={!unsavedChanges}
        >
          {!hardware
            ? t("hardwareScreen.form.save")
            : t("hardwareScreen.form.update")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default HardwareForm;
