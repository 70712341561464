import React, { useEffect } from "react";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import LoggedInArea from "./components/LoggedInArea";
import NotLoggedInArea from "./components/NotLoggedInArea";
import { getUserProfile } from "./redux/userSlice";

const publicRoutes = [
  "/app/forget-password",
  "/app/login",
  "/app/check-reset-code",
  "/app/reset-password",
];

const defaultData = {
  borderRadius: 6,
  colorPrimary: process.env.REACT_APP_PRIMARY_COLOR,
  Button: {
    colorPrimary: process.env.REACT_APP_PRIMARY_COLOR,
  },
};

function App() {
  const { auth } = useSelector((state) => state.auth);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const isPublicRoute = publicRoutes.includes(location.pathname);

    if (!auth.token && !isPublicRoute) {
      navigate("/app/login");
    } else if (auth.token && isPublicRoute) {
      navigate("/app/main");
    }
  }, [auth.token, location]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: defaultData.colorPrimary,
          borderRadius: defaultData.borderRadius,
        },
        components: {
          Button: {
            colorPrimary: defaultData.Button?.colorPrimary,
            algorithm: defaultData.Button?.algorithm,
          },
        },
      }}
      componentSize="middle"
    >
      <Routes>
        <Route path="/app/main/*" element={<LoggedInArea />} />

        {/* Not Logged-in Area */}
        <Route path="/app/*" element={<NotLoggedInArea />} />

        {/* Redirect to the appropriate area */}
        <Route path="/" element={<Navigate to="/app/login" />} />
      </Routes>
    </ConfigProvider>
  );
}

export default App;
