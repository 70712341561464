import React, { useEffect, useState } from "react";
import {
  getAllHardwareLogs,
  getHardwareLogsByDateRangeAndDevice,
} from "../../redux/hardwareLogSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomTableComponents from "../CommonComponents/CustomTableComponents";
import { Card, message } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import Header from "../../global/Header/Header";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import FilteringDrawer from "./Filter/FilteringDrawer";
import { getAllHardwares } from "../../redux/hardwareSlice";
import { formatDateAndTime } from "../../Utils/Imports/date-utils";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { getAllKiosks } from "../../redux/kiosksSlice";

const today = dayjs().startOf("day");
const tomorrow = dayjs().add(1, "day").startOf("day");

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);

const HardwareLogs = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("hardwareLogScreen.table.date"),
      key: "date",
      render: (record) => {
        return record.created ? (
          <span>{formatDateAndTime(record.created)}</span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => new Date(a.created) - new Date(b.created),
      show: true,
    },
    {
      title: t("hardwareLogScreen.table.hardwareId"),
      key: "hardwareId",
      render: (record) => {
        return record.hardwareId ? (
          <span>{record.hardwareId}</span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => a.hardwareId - b.hardwareId,
      show: true,
    },
    {
      title: t("hardwareLogScreen.table.errorType"),
      key: "errorType",
      render: (record) => {
        return record?.errorType ? (
          <span>{record?.errorType}</span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => a?.errorType?.length - b?.errorType?.length,
      show: true,
    },
    {
      title: t("hardwareLogScreen.table.reason"),
      key: "reason",
      render: (record) => {
        return record.reason ? <span>{record.reason}</span> : <MinusOutlined />;
      },
      sorter: (a, b) => a.reason - b.reason,
      show: true,
    },
  ];

  const [filter, setFilter] = useState("");
  const [isMakingApiCall, setMakingApiCall] = useState(true);
  const [isFilerDrawerOpen, setisFilerDrawerOpen] = useState(false);
  const [dateRange, setDateRange] = useState([today, tomorrow]);

  const [csvHeaders, setCsvHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const openFilterDrawer = () => {
    setisFilerDrawerOpen(true);
  };
  const closeFilterDrawer = () => {
    setisFilerDrawerOpen(false);
  };

  const dispatch = useDispatch();
  const { hardwareLogs } = useSelector((state) => state.hardwareLogs);
  const { hardwares } = useSelector((state) => state.hardwares);
  const { kiosks } = useSelector((state) => state.kiosks);

  const [selectedOptionDropdownFields, setSelectedOptionDropdownFields] =
    useState({
      hardwares: null,
      kiosk: null,
    });

  const dropdownFieldsToShowInFilter = [
    /* {
      label: t("hardwareLogScreen.filterDrawer.kiosk"),
      key: "kiosk",
      options: kiosks?.kiosksList || [], // List of objects
      optionLabel: "name",
      optionValue: "id",
    }, */
    {
      label: t("hardwareLogScreen.filterDrawer.hardware"),
      key: "hardwares",
      options: hardwares?.data || [], // List of objects
      optionLabel: "name",
      optionValue: "id",
    },
  ]
  const location = useLocation();
  

  const fetchLogs = () => {
    setMakingApiCall(true);
    // Set start to 00:00
    const start = dateRange[0]
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    // Set end to 23:59
    const end = dateRange[1].endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const { filterFromKiosk } = location.state || {};
    if(filterFromKiosk?.printer) {
      handleSelectDorpdownsChange("hardwares", filterFromKiosk?.printer?.id)
      //handleSelectDorpdownsChange("kiosk", filterFromKiosk?.kiosk)
      location.state = {}
    }
    dispatch(
      getHardwareLogsByDateRangeAndDevice({
        start: start !== undefined ? start : null,
        end: end !== undefined ? end : null,
        deviceId: selectedOptionDropdownFields?.hardwares,
        //kioskId: selectedOptionDropdownFields?.kiosk
      })
    )
      .unwrap()
      .then(() => setMakingApiCall(false))
      .catch(() => setMakingApiCall(false));
  };

  const fetchHardwares = () => {
    setMakingApiCall(false);
    dispatch(getAllHardwares())
      .unwrap()
      .then(() => setMakingApiCall(false))
      .catch(() => setMakingApiCall(false));
  };

  const fetchKiosks = async () => {
    setMakingApiCall(true);
    dispatch(getAllKiosks())
      .unwrap()
      .then(() => {})
      .catch((err) => {
        message.error("Error while fetching kiosks");
      })
      .finally(() => setMakingApiCall(false));
  };

  useEffect(() => {
    fetchLogs();
  }, [dispatch, dateRange]);

  useEffect(() => {
    fetchHardwares();
  }, [dispatch]);

  const filteredData = Array.isArray(hardwareLogs.data)
    ? hardwareLogs.data.filter((item) => {
        return (
          item?.hardwareId
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
          item?.status
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
          item?.reason
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
          new Date(item.created)
            .toLocaleDateString()
            .toLowerCase()
            .includes(filter.toLowerCase())
        );
      })
    : [];

  const onSaveFilter = () => {
    // Set start to 00:00
    const start = dateRange[0]
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    // Set end to 23:59
    const end = dateRange[1].endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const payload = {
      start,
      end,
      deviceId: selectedOptionDropdownFields?.hardwares,
      //kioskId: selectedOptionDropdownFields?.kiosk
    };
    dispatch(getHardwareLogsByDateRangeAndDevice(payload))
      .unwrap()
      .then(() => {
        setMakingApiCall(false)
        closeFilterDrawer();
      })
      .catch(() => setMakingApiCall(false));
  };

  const handleGenerateCsv = (event, done) => {
    const promise = async () => {

      const createdCsvData = filteredData.map((item) => {
        return {...item, date: formatDateAndTime(item.created)}
      })
      setCsvData(createdCsvData)
      setCsvHeaders([
        {
          label: "Date",
          key: "date",
        },
        {
          label: "Hardware Id",
          key: "hardwareId",
        },
        {
          label: "Error Type",
          key: "errorType",
        },
        {
          label: "Reason",
          key: "reason",
        },
      ]);
    };

    promise().then(done());
  };

  const onFilterByDate = (date, dateString) => {
    const startDate = dayjs(dateString[0]);
    const endDate = dayjs(dateString[1]);
    setDateRange([startDate, endDate]);
  };

  const handleSelectDorpdownsChange = (key, value) => {
    setSelectedOptionDropdownFields((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <>
      <Header
        onAddButtonClick={() => {}}
        onSearch={(e) => {
          setFilter(e.target.value);
        }}
        searchPlaceholder={t("hardwareLogScreen.header.search")}
        addButtonTextColor={"#ffffff"}
        onReload={fetchLogs}
        showAddButton={false}
        BreadcrumbItems={[
          {
            title: t("hardwareLogScreen.header.subtitle"),
          },
          {
            title: t("hardwareLogScreen.header.title"),
          },
        ]}
        showBreadcrumb={true}
        showFilterButton={true}
        onClickFilterButton={() => {
          openFilterDrawer();
        }}
        showExportCSV={true}
        csvHeaders={csvHeaders}
        csvData={csvData}
        generateCSVfn={handleGenerateCsv}
        csvDataMiddleFileName={"Hardware-logs"}
        showFilterByDate={true}
        onFilterByDate={(date, dateString) => onFilterByDate(date, dateString)}
        dateRange={dateRange}
      />
      <Card style={{ margin: 16, height: "65vh" }}>
        <CustomTableComponents
          isMakingApiCall={isMakingApiCall}
          columns={columns.filter((column) => column.show)}
          dataArray={filteredData}
          handleDoubleClickRow={() => {}}
          handleSelectedRow={() => {}}
        />
        {isFilerDrawerOpen && (
          <FilteringDrawer
            closeFilteringDrawer={closeFilterDrawer}
            isFilteringDrawerOpened={isFilerDrawerOpen}
            resetFilter={fetchLogs}
            loading={false}
            formName={"Hardware log filter"}
            title={t("hardwareLogScreen.filterDrawer.hardwareLogFilter")}
            onSave={() => onSaveFilter()}
            type={"Hardware"}
            showSelect={true}
            selectLabel={t("validatorLogScreen.filterDrawer.hardwares")}
            selectValue={"serialNumber"}
            selectKey={"id"}
            data={hardwares?.data || []}
            optionValue={"serialNumber"}
            displayDateRange={false}
            displayOneDropdown={false}
            /* dateRange={dateRange}
            setDateRange={setDateRange} 
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}*/
            fieldsOfDropdowns={dropdownFieldsToShowInFilter}
            selectedOptionOfDropdownFields={selectedOptionDropdownFields}
            setSelectedOptionOfDropdownFields={handleSelectDorpdownsChange}
          />
        )}
      </Card>
    </>
  );
};

export default HardwareLogs;
