import React, { useState, useEffect, useCallback } from "react";
import { Drawer, message, Spin } from "antd";
import { useDispatch } from "react-redux";
import {
  addKioskOrValidatorUser,
  addNewUser,
  getUserById,
  updateUser,
} from "../../redux/userSlice";
import ShowConfirmClose from "../Modals/ShowConfirmClose";
import UserForm from "./UserForm";
import { uploadProfilPicture } from "../../redux/fileSlice";
import KioskAndValidatorUserForm from "./KioskAndValidatorUserForm";
import { useTranslation } from "react-i18next";

const DrawerUser = ({ onClose, userId, type, role, translatedType }) => {
  const { t } = useTranslation();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const dispatch = useDispatch();
  const [makingApiCall, setMakingApiCall] = useState(false);
  const [user, setUser] = useState(undefined);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [isFileChosen, setIsFileChosen] = useState(false);

  const handleUpload = (callback, values) => {
    if (fileList.length !== 0 && isFileChosen) {
      setUploading(true);
      dispatch(uploadProfilPicture(fileList[0]?.originFileObj))
        .unwrap()
        .then((res) => {
          setFileList([]);
          callback(values, res.id);
        })
        .catch(() => {
          message.error("upload failed.");
        })
        .finally(() => {
          setUploading(false);
        });
    } else callback(values, null);
  };

  useEffect(() => {
    const abortController = new AbortController();

    if (userId) {
      setMakingApiCall(true);
      const calls = [
        dispatch(
          getUserById({
            userId,
          })
        ),
      ];
      Promise.all(calls)
        .then((res) => {
          if (userId) {
            setUser(res[0].payload);
            /* if (
							res[0].payload?.profilePicture &&
							res[0].payload?.profilePicture !== 0
						) {
							fetchImage(res[0].payload?.profilePicture).then((imgUrl) => {
								setFileList([
									{
										uid: '-1',
										name: 'image.png',
										status: 'done',
										url: imgUrl,
									},
								]);
							});
						} */
          }
        })
        .catch(() => {
          onClose();
        })
        .finally(() => {
          setMakingApiCall(false);
        });
    }
    return () => abortController.abort();
  }, [userId, dispatch]);

  const handleDirty = useCallback(() => {
    setUnsavedChanges(true);
  }, []);

  const handleSaveUser = async (values) => {
    setMakingApiCall(true);
    await handleUpload(saveUser, values); // Wait for the upload to complete and update the pictureId
  };
  const onSave = async (values) => {
    if (role === "KIOSK_ROLE" || role === "VALIDATOR_ROLE")
      await saveKioskAndValidatorUser(values);
    else {
      await await handleSaveUser(values);
    }
  };

  const saveKioskAndValidatorUser = async (values) => {
    const action = addKioskOrValidatorUser(values);
    dispatch(action)
      .unwrap()
      .then(() => {
        message.success(t("userScreen.messages.userSavedSuccessfully"));
        onClose();
        setUnsavedChanges(false);
      })
      .catch((e) => {
        if (e.message === "MACHINE_ALREADY_ASSIGNED_TO_A_USER") {
          message.error(
            t("userScreen.messages.couldNotSaveMachineAlreadyAssigned")
          );
        } else {
          message.error(e.message);
        }
      })
      .finally(() => {
        setMakingApiCall(false);
      });
  };

  const saveUser = async (values, profilePictureId) => {
    const action = userId
      ? updateUser({
          userId: user.id,
          user: {
            ...values,
            profilePhoto: isFileChosen ? profilePictureId : user.profilePicture,
            active: user.active,
          },
        })
      : addNewUser({
          user: { ...values, profilePhoto: profilePictureId },
        });
    console.log({
      user: { ...values, profilePhoto: profilePictureId },
    });
    await dispatch(action)
      .unwrap()
      .then(() => {
        message.success(t("userScreen.messages.userSavedSuccessfully"));
        onClose();
        setUnsavedChanges(false);
      })
      .catch((e) => {
        if (e.message === "User_ALREADY_EXISTS") {
          message.error(t("userScreen.messages.couldNotSaveUserAlreadyExists"));
        } else {
          message.error(e.message);
          
        }
      })
      .finally(() => {
        setMakingApiCall(false);
      });
  };

  const onSaveFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleClose = useCallback(
    (hasUnsavedChanges) => {
      if (hasUnsavedChanges && !confirmationOpen) {
        setConfirmationOpen(true); // Mark that the confirmation modal is open
        ShowConfirmClose(() => {
          setConfirmationOpen(false); // Reset when the confirmation modal is closed
          onClose();
        });
      } else {
        onClose();
      }
    },
    [onClose, confirmationOpen]
  );
  console.log("translatedType", translatedType);
  return (
    <Drawer
      title={
        !userId
          ? t("userScreen.form.add_type", {
              type: translatedType ? translatedType : type,
            })
          : t("userScreen.form.update_type", {
              type: translatedType ? translatedType : type,
            })
      }
      placement="right"
      onClose={() => handleClose(unsavedChanges)}
      width={500}
      open
    >
      <Spin
        size="large"
        spinning={makingApiCall}
        style={{ marginTop: 64 }}
        delay={500}
      >
        {!makingApiCall &&
          role !== "KIOSK_ROLE" &&
          role !== "VALIDATOR_ROLE" && (
            <UserForm
              user={user}
              onSave={onSave}
              onSaveFailed={onSaveFailed}
              onCancel={handleClose}
              onDirty={handleDirty}
              unsavedChanges={unsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
              fileList={fileList}
              setFileList={setFileList}
              handleUpload={handleUpload}
              uploading={uploading}
              setIsFileChosen={setIsFileChosen}
              role={role}
            />
          )}
        {!makingApiCall &&
          (role === "KIOSK_ROLE" || role === "VALIDATOR_ROLE") && (
            <KioskAndValidatorUserForm
              type={type}
              user={user}
              onSave={onSave}
              onSaveFailed={onSaveFailed}
              onCancel={handleClose}
              onDirty={handleDirty}
              unsavedChanges={unsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
              fileList={fileList}
              setFileList={setFileList}
              handleUpload={handleUpload}
              uploading={uploading}
              setIsFileChosen={setIsFileChosen}
              translatedType={translatedType}
            />
          )}
      </Spin>
    </Drawer>
  );
};

export default DrawerUser;
