import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { style } from "../../Styles";
import CustomTableComponents from "../CommonComponents/CustomTableComponents";
import { Breadcrumb, Button, Card, message } from "antd";
import { ReloadOutlined, MinusOutlined } from "@ant-design/icons";
import { getTicketsBetweenDate } from "../../redux/ticketSlice";
import { getAllKiosks } from "../../redux/kiosksSlice";
import { formatDateAndTime } from "../../Utils/Imports/date-utils";
import Header from "../../global/Header/Header";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import FilteringDrawer from "../Logs/Filter/FilteringDrawer";
import { useTranslation } from "react-i18next";

const today = dayjs().startOf("day");
const tomorrow = dayjs().add(1, "day").startOf("day");

const Ticket = () => {
  const { t } = useTranslation();
  dayjs.extend(isBetween);
  const columns = [
    {
      title: t("ticketScreen.table.ticketNumber"),
      key: "id",
      render: (record) => {
        return <span>{record?.id}</span>;
      },
      sorter: (a, b) => a.id - b.id,
      show: true,
    },
    {
      title: t("ticketScreen.table.referenceNumber"),
      key: "ticketReferenceNumber",
      render: (record) => {
        return <span>{record.ticketReferenceNumber}</span>;
      },
      sorter: (a, b) => a.ticketReferenceNumber - b.ticketReferenceNumber,
      show: true,
    },
    {
      title: t("ticketScreen.table.printedDate"),
      key: "printedDate",
      render: (record) => {
        return record?.created ? (
          formatDateAndTime(record?.created)
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => {
        const dateA = new Date(a.created);
        const dateB = new Date(b.created);
        return dateA.getTime() - dateB.getTime();
      },
      show: true,
    },
    {
      title: t("ticketScreen.table.bundle"),
      key: "bundle",
      render: (record) => {
        return <span>{record.bundle?.name}</span>;
      },
      sorter: (a, b) => a.bundle?.name.length - b.bundle?.name.length,
      show: true,
    },
    {
      title: t("ticketScreen.table.numberOfScans"),
      key: "numberOfScan",
      render: (record) => {
        return (
          <span style={style.dataContainer}>
            {record.numberOfScan}/{record?.bundle?.numberOfTrips}{" "}
          </span>
        );
      },
      sorter: (a, b) => a.numberOfScan - b.numberOfScan,
      show: true,
    },
  ];

  const [filter, setFilter] = useState("");
  const [dateFilter, setDateFilter] = useState([today, tomorrow]);
  const [isMakingApiCall, setMakingApiCall] = useState(true);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [isFilerDrawerOpen, setisFilerDrawerOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const dispatch = useDispatch();
  const { tickets } = useSelector((state) => state.tickets);
  const { kiosks } = useSelector((state) => state.kiosks);
  const fetchTickets = () => {
    setMakingApiCall(true);
    // Set start to 00:00
    const start = dateFilter[0]
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    // Set end to 23:59
    const end = dateFilter[1].endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    dispatch(
      getTicketsBetweenDate({
        start: start !== undefined ? start : null,
        end: end !== undefined ? end : null,
        kioskId: selectedOption,
      })
    )
      .unwrap()
      .then((data) => {
        setMakingApiCall(false);
      })
      .catch(() => setMakingApiCall(false));
  };

  useEffect(() => {
    fetchTickets();
  }, [dispatch, dateFilter, selectedOption]);

  const fetchKiosks = async () => {
    setMakingApiCall(true);
    dispatch(getAllKiosks())
      .unwrap()
      .then(() => {})
      .catch((err) => {
        message.error("Error while fetching kiosks");
      })
      .finally(() => setMakingApiCall(false));
  };

  useEffect(() => {
    fetchKiosks();
  }, [dispatch]);

  const handleGenerateCsv = (event, done) => {
    const promise = async () => {
      const createdCsvData = filteredData.map((item) => {
        return { ...item, created: formatDateAndTime(item.created) };
      });
      setCsvData(createdCsvData);
      setCsvHeaders([
        {
          label: "Ticket Number",
          key: "id",
        },
        {
          label: "Reference Number",
          key: "ticketReferenceNumber",
        },
        {
          label: "Printed Date",
          key: "created",
        },
        {
          label: "Bundle",
          key: "bundle.name",
        },
        {
          label: "Number of Scans",
          key: "numberOfScan",
        },
      ]);
    };

    promise().then(done());
  };

  const isDateWithinRange = (itemDate, dateFilter) => {
    if (dateFilter.length === 2 && dateFilter[0] && dateFilter[1]) {
      const startDate = dayjs(dateFilter[0]);
      const endDate = dayjs(dateFilter[1]);
      return (
        itemDate.isSame(startDate) ||
        itemDate.isSame(endDate) ||
        itemDate.isBetween(startDate, endDate, null, "[)")
      );
    }
    return true;
  };

  const matchesFilter = (item, filter) => {
    const filterLower = filter.trim().toLowerCase();
    return (
      item?.ticketReferenceNumber
        ?.toString()
        .toLowerCase()
        .includes(filterLower) ||
      item?.created?.toString().toLowerCase().includes(filterLower) ||
      item?.bundle?.name?.toString().toLowerCase().includes(filterLower)
    );
  };

  const filterTickets = (tickets, filter, dateFilter) => {
    return tickets?.filter((item) => {
      const itemDate = dayjs(item?.created).format("YYYY-MM-DD");
      const isWithinRange = isDateWithinRange(dayjs(itemDate), dateFilter);
      return matchesFilter(item, filter) && isWithinRange;
    });
  };

  const filteredData = filterTickets(tickets, filter, dateFilter);

  const onFilterByDate = (date, dateString) => {
    const startDate = dayjs(dateString[0]);
    const endDate = dayjs(dateString[1]);
    setDateFilter([startDate, endDate]);
  };

  const openFilterDrawer = () => {
    setisFilerDrawerOpen(true);
  };
  const closeFilterDrawer = () => {
    setisFilerDrawerOpen(false);
  };

  const onSaveFilter = () => {
    fetchTickets();
    closeFilterDrawer();
  };
  return (
    <>
      <Header
        onAddButtonClick={() => {}}
        onSearch={(e) => {
          setFilter(e.target.value);
        }}
        searchPlaceholder={t("ticketScreen.header.search")}
        addButtonTextColor={"#ffffff"}
        onReload={fetchTickets}
        showAddButton={false}
        BreadcrumbItems={[
          {
            title: t("ticketScreen.header.title"),
          },
        ]}
        showBreadcrumb={true}
        showExportCSV={true}
        csvHeaders={csvHeaders}
        csvData={csvData}
        generateCSVfn={handleGenerateCsv}
        csvDataMiddleFileName={"Ticket"}
        showFilterByDate={true}
        onFilterByDate={(date, dateString) => onFilterByDate(date, dateString)}
        showFilterButton={true}
        onClickFilterButton={() => {
          openFilterDrawer();
        }}
        dateRange={dateFilter}
      />
      <Card style={{ margin: 16, height: "85vh" }}>
        <CustomTableComponents
          isMakingApiCall={isMakingApiCall}
          columns={columns.filter((column) => column.show)}
          dataArray={filteredData}
          handleDoubleClickRow={() => {}}
          handleSelectedRow={() => {}}
        />
        {isFilerDrawerOpen && (
          <FilteringDrawer
            closeFilteringDrawer={closeFilterDrawer}
            isFilteringDrawerOpened={isFilerDrawerOpen}
            resetFilter={fetchTickets}
            loading={false}
            formName={"Tickets filter"}
            title={t("ticketScreen.drawer.title")}
            onSave={() => onSaveFilter()}
            displayDateRange={false}
            selectLabel={t("ticketScreen.drawer.kiosks")}
            selectValue={"name"}
            selectKey={"name"}
            data={kiosks?.kiosksList || []}
            optionValue={"name"}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        )}
      </Card>
    </>
  );
};

export default Ticket;
