import { Card, Tag, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import ActionMenu from "./ActionMenu";
import CustomAvatar from "./Avatar/CustomAvatar";
import { ClockCircleOutlined, MinusOutlined } from "@ant-design/icons";
import { style } from "../Utils/Style/Style";
import { formatDate } from "../Utils/Constants/utils";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

function KioskCardComponent({ id, kiosk }) {
  const { t } = useTranslation();
  const [cardDispenser, setCardDispenser] = useState([]);

  useEffect(() => {
    kiosk.hardware?.map((hardware) => {
      if (
        hardware.name === "CARD_DISPENSER" &&
        !cardDispenser.some((h) => h.id === hardware.id)
      ) {
        setCardDispenser([...cardDispenser, hardware]);
      } else if (
        hardware.name === "CARD_DISPENSER" &&
        cardDispenser.some((h) => h.id === hardware.id)
      ) {
        setCardDispenser(
          cardDispenser.map((h) =>
            h.id === hardware.id ? { ...h, ...hardware } : h
          )
        );
      }
    });
  }, [kiosk.hardware]);

  return (
    <Card
      style={{
        ...style.boxShadow,
        margin: "15px 0",
        width: "85%",
      }}
      type="inner"
      title={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center", // Align items vertically in the center
          }}
        >
          <div
            style={{
              width: 25,
              height: 25,
              borderRadius: 50,
              backgroundColor: kiosk.active ? "green" : "red",
              marginRight: 8, // Add margin to separate the badge and text
            }}
          />
          <Tooltip title={kiosk?.name ? kiosk?.name : <MinusOutlined />}>
            <h2
              style={{
                marginLeft: 8,
                maxWidth: "70%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {kiosk?.name ? kiosk?.name : <MinusOutlined />}
            </h2>
          </Tooltip>
        </div>
      }
      extra={<ActionMenu record={kiosk} />}
      actions={[
        <div style={{ margin: 0, padding: "0 24px" }}>
          {kiosk?.type != "PORTABLE_KIOSK" && (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <h2
                style={{
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  flex: "1 1 100%",
                }}
              >
                {t("kioskScreen.kioskCard.actions.lastMaintenance")}
              </h2>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 16,
                  flex: "1 1 100%",
                }}
              >
                <ClockCircleOutlined
                  style={{ marginRight: 8, fontSize: "23px" }}
                />
                <span style={{ marginLeft: 3, fontSize: "17px" }}>
                  {kiosk?.maintenance?.created ? (
                    formatDate(kiosk?.maintenance?.created)
                  ) : (
                    <MinusOutlined />
                  )}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flex: "1 1 100%",
                  margin: "-10px 0 -10px 16px",
                }}
              >
                <CustomAvatar
                  name={
                    `${kiosk?.maintenance?.technician?.name} ${kiosk?.maintenance?.technician?.surname}` ||
                    "User"
                  }
                  type="initials"
                  size={25}
                />
                <p style={{ fontSize: 17 }}>
                  {kiosk?.maintenance?.technician ? (
                    `${t("kioskScreen.kioskCard.actions.by")} ${
                      kiosk?.maintenance?.technician?.name
                    } ${kiosk?.maintenance?.technician?.surname}`
                  ) : (
                    <MinusOutlined />
                  )}
                </p>
              </div>
            </div>
          )}
        </div>,
      ]}
    >
      <div
        style={{
          ...style.containShadow,
          padding: 16,
          backgroundColor: "#ecefff",
        }}
      >
        {kiosk?.type === "KIOSK_STATION" && (
          <div style={style.displayFlexDirectionRow}>
            <Text style={style.cardTextStyle} strong>
              {t("kioskScreen.kioskCard.content.rollStatus")}
            </Text>
            <div
              style={{
                ...style.cardTextStyle,
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <Tag
                key={kiosk?.id}
                color={kiosk?.hardwareStatus?.rollStatus ? "green" : "red"}
                style={{
                  ...style.skillTag,
                  margin: "0px",
                }}
              >
                {kiosk?.hardwareStatus?.rollStatus ? (
                  "OK"
                ) : (
                  <Tooltip
                    placement="top"
                    title={kiosk?.hardwareStatus?.reason}
                    color="#E53935"
                  >
                    <Text style={{ color: "red" }}>ERROR</Text>
                  </Tooltip>
                )}
              </Tag>
            </div>
          </div>
        )}
        {kiosk?.type === "KIOSK_STATION" && (
          <div style={style.displayFlexDirectionRow}>
            <Text style={style.cardTextStyle} strong>
              {t("kioskScreen.kioskCard.content.printerStatus")}
            </Text>
            <div
              style={{
                ...style.cardTextStyle,
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <Tag
                key={kiosk?.id}
                color={kiosk?.hardwareStatus?.printerStatus ? "green" : "red"}
                style={{
                  ...style.skillTag,
                  margin: "0px",
                }}
              >
                {kiosk?.hardwareStatus?.printerStatus ? (
                  "OK"
                ) : (
                  <Tooltip
                    placement="top"
                    title={kiosk?.hardwareStatus?.reason}
                    color="#E53935"
                  >
                    <Text style={{ color: "red" }}>ERROR</Text>
                  </Tooltip>
                )}
              </Tag>
            </div>
          </div>
        )}
        <div style={style.displayFlexDirectionRow}>
          <Text style={style.cardTextStyle} strong>
            {t("kioskScreen.kioskCard.content.cardSalesLast24Hours")}
          </Text>
          <Text style={style.cardTextStyle} code>
            {kiosk?.hardwareStatus?.cardSoldLast24Hours ||
            kiosk?.hardwareStatus?.cardSoldLast24Hours === 0 ? (
              kiosk?.hardwareStatus?.cardSoldLast24Hours
            ) : (
              <MinusOutlined />
            )}
          </Text>
        </div>
        <div style={style.displayFlexDirectionRow}>
          <Text style={style.cardTextStyle} strong>
            {t("kioskScreen.kioskCard.content.cardTopUpLast24Hours")}
          </Text>
          <Text style={style.cardTextStyle} code>
            {kiosk?.hardwareStatus?.cardTopUpLast24Hours ||
            kiosk?.hardwareStatus?.cardTopUpLast24Hours === 0 ? (
              kiosk?.hardwareStatus?.cardTopUpLast24Hours
            ) : (
              <MinusOutlined />
            )}
          </Text>
        </div>
        <div style={style.displayFlexDirectionRow}>
          <Text style={style.cardTextStyle} strong>
            {t("kioskScreen.kioskCard.content.ticketSalesLast24Hours")}
          </Text>
          <Text style={style.cardTextStyle} code>
            {kiosk?.hardwareStatus?.ticketSoldLast24Hours ||
            kiosk?.hardwareStatus?.ticketSoldLast24Hours === 0 ? (
              kiosk?.hardwareStatus?.ticketSoldLast24Hours
            ) : (
              <MinusOutlined />
            )}
          </Text>
        </div>
        {cardDispenser.map((cardDispenser, index) => (
          <div key={index}>
            <div style={style.displayFlexDirectionRow}>
              <Text style={style.cardTextStyle} strong>
                {t("kioskScreen.kioskCard.content.cardStockTray1")}
                {index + 1} :
              </Text>
              <Text style={style.cardTextStyle} code>
                {cardDispenser?.stock || cardDispenser?.stock === 0 ? (
                  cardDispenser?.stock
                ) : (
                  <MinusOutlined />
                )}
              </Text>
            </div>
            <div style={style.displayFlexDirectionRow}>
              <Text style={style.cardTextStyle} strong>
                {t("kioskScreen.kioskCard.content.cardBinTray1")}
                {index + 1} :
              </Text>
              <Text style={style.cardTextStyle} code>
                {cardDispenser?.bin || cardDispenser?.bin === 0 ? (
                  cardDispenser?.bin
                ) : (
                  <MinusOutlined />
                )}
              </Text>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}

export default KioskCardComponent;
