import React, { useEffect, useState } from "react";
import { getCards, getCardsBetweenDate } from "../../redux/cardSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { style } from "../../Styles";
import CustomTableComponents from "../CommonComponents/CustomTableComponents";
import { Card } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import Header from "../../global/Header/Header";
import dayjs, { Dayjs } from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';

const today = dayjs().startOf("day");
const tomorrow = dayjs().add(1, "day").startOf("day");

const Cards = () => {
  const { t } = useTranslation();
  dayjs.extend(isBetween);

  const columns = [
    {
      title: t("cardScreen.table.cardNumber"),
      key: "cardNumber",
      render: (record) => {
        return <span>{record.cardNumber}</span>;
      },
      sorter: (a, b) => a.cardNumber.length - b.cardNumber.length,
      show: true,
    },
    {
      title: t("cardScreen.table.totalNumberOfTrips"),
      key: "totalNumberOfTrips",
      render: (record) => {
        return record.totalNumberOfTrips ? (
          <span>{record.totalNumberOfTrips}</span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => a.totalNumberOfTrips - b.totalNumberOfTrips,
      show: true,
    },
    {
      title: t("cardScreen.table.usedTrips"),
      key: "usedTrips",
      render: (record) => {
        return record.usedTrips ? (
          <span>{record.usedTrips}</span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => a.usedTrips - b.usedTrips,
      show: true,
    },
    {
      title: t("cardScreen.table.status"),
      key: "active",
      render: (record) => {
        return (
          <span style={style.dataContainer}>
            {record.active ? (
              <div style={style.statusActif}>{t("statuses.active")}</div>
            ) : (
              <div
                style={{
                  ...style.statusInactif,
                  capitalize: "capitalize",
                }}
              >
                {t("statuses.inactive")}
              </div>
            )}
          </span>
        );
      },
      sorter: (a, b) => a.active.length - b.active.length,
      show: true,
    },
  ];

  const [filter, setFilter] = useState("");
  const [isMakingApiCall, setMakingApiCall] = useState(true);
  const [dateFilter, setDateFilter] = useState([today, tomorrow]);

  const [csvHeaders, setCsvHeaders] = useState([]);

  const dispatch = useDispatch();
  const { cards } = useSelector((state) => state.cards);

  const fetchCards = () => {
    setMakingApiCall(true);
        // Set start to 00:00
        const start = dateFilter[0]
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    
        // Set end to 23:59
        const end = dateFilter[1].endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        dispatch(
          getCardsBetweenDate({
            start: start !== undefined ? start : null,
            end: end !== undefined ? end : null,
          })
        )
          .unwrap()
          .then((data) => {
            setMakingApiCall(false)})
          .catch(() => setMakingApiCall(false));
  };

  useEffect(() => {
    fetchCards();
  }, [dispatch, dateFilter]);

  const handleGenerateCsv = (event, done) => {
    const promise = async () => {
      //setCsvData(filteredHardware);
      setCsvHeaders([
        {
          label: "Card Number",
          key: "cardNumber",
        },
        {
          label: "Total Number Of Trips",
          key: "totalNumberOfTrips",
        },
        {
          label: "Used Trips",
          key: "usedTrips",
        },
        {
          label: "status",
          key: "active",
        },
      ]);
    };

    promise().then(done());
  };

  /* const isDateWithinRange = (itemDate, dateFilter) => {
    if (dateFilter.length === 2 && dateFilter[0] && dateFilter[1]) {
      const startDate = dayjs(dateFilter[0]);
      const endDate = dayjs(dateFilter[1]);
      return itemDate.isSame(startDate) || itemDate.isSame(endDate) || itemDate.isBetween(startDate, endDate, null, '[)');
    }
    return true;
  }; */
  
  const matchesFilter = (item, filter) => {
    const filterLower = filter.trim().toLowerCase();
    return (
      item?.cardNumber
      .toString()
      .toLowerCase()
      ?.includes(filterLower.toLowerCase()) ||
    item?.usedTrips.toString().toLowerCase().includes(filterLower.toLowerCase()) ||
    item?.totalNumberOfTrips
      .toString()
      .toLowerCase()
      ?.includes(filterLower.toLowerCase()) ||
    item?.transaction?.bundle?.name
      .toLowerCase()
      ?.includes(filterLower.toLowerCase()) ||
    item?.active.toString().toLowerCase()?.includes(filterLower.toLowerCase())
    );
  };
  
  const filterCards = (cards, filter, dateFilter) => {
    return cards?.filter((item) => {
      const itemDate = dayjs(item?.created).format('YYYY-MM-DD');
      //const isWithinRange = isDateWithinRange(dayjs(itemDate), dateFilter);
      return matchesFilter(item, filter);
    });
  };

   const filtredData = filterCards(cards, filter, dateFilter);


  const onFilterByDate = (date, dateString) => {

    const startDate = dayjs(dateString[0]);
    const endDate = dayjs(dateString[1]);
    setDateFilter([startDate, endDate]);
  }

  return (
    <>
      <Header
        onAddButtonClick={() => {}}
        onSearch={(e) => {
          setFilter(e.target.value);
        }}
        searchPlaceholder={t("cardScreen.header.search")}
        addButtonTextColor={"#ffffff"}
        onReload={fetchCards}
        showAddButton={false}
        BreadcrumbItems={[
          {
            title: t("cardScreen.header.title"),
          },
        ]}
        showBreadcrumb={true}
        showExportCSV={true}
        csvHeaders={csvHeaders}
        csvData={filtredData}
        generateCSVfn={handleGenerateCsv}
        csvDataMiddleFileName={"Cards"}
        showFilterByDate={true}
        onFilterByDate={(date, dateString) => onFilterByDate(date, dateString)}
        dateRange={dateFilter}
      />
      <Card style={{ margin: 16, height: "65vh" }}>
        <CustomTableComponents
          isMakingApiCall={isMakingApiCall}
          columns={columns.filter((column) => column.show)}
          dataArray={filtredData}
          handleDoubleClickRow={() => {}}
          handleSelectedRow={() => {}}
        />
      </Card>
    </>
  );
};

export default Cards;
