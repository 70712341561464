import React, { useEffect, useState } from "react";
import Header from "../../global/Header/Header";
import { MinusOutlined } from "@ant-design/icons";
import { style } from "../../Styles";
import CustomTableComponents from "../CommonComponents/CustomTableComponents";
import { Card, Typography, message } from "antd";
import {
  changeHardwareStatus,
  deleteHardware,
  getAllHardwares,
} from "../../redux/hardwareSlice";
import { useDispatch, useSelector } from "react-redux";
import HardwareDrawer from "./HardwareDrawer";
import ActionButtonWithChangeStatus from "../CommonComponents/ActionButtonWithChangeStatus";
import { getHardwareName } from "../../Utils/Constants/utils";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const Hardwares = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("hardwareScreen.table.name"),
      key: "name",
      render: (record) => {
        return record.name ? (
          <span style={style.dataContainer}>
            <div style={style.tagItem}>
              {getHardwareName(record.name)?.label}
            </div>
          </span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
      show: true,
    },
    {
      title: t("hardwareScreen.table.serialNumber"),
      key: "serialNumber",
      render: (record) => {
        return record.serialNumber ? (
          <Text copyable keyboard>
            {record.serialNumber}
          </Text>
        ) : (
          <MinusOutlined />
        );
      },

      sorter: (a, b) => a?.serialNumber?.localeCompare(b?.serialNumber),
      show: true,
    },
    {
      title: t("hardwareScreen.table.kiosk"),
      key: "kioskName",
      render: (record) => <span>{record.kiosk?.name}</span>,
      sorter: (a, b) => a?.kiosk?.name.localeCompare(b?.kiosk?.name),
      show: true,
    },
    {
      title: t("hardwareScreen.table.stock"),
      key: "stock",
      render: (record) => {
        return record.stock ? <span>{record.stock}</span> : <MinusOutlined />;
      },
      sorter: (a, b) => a.stock - b.stock,
      show: true,
    },
    {
      title: t("hardwareScreen.table.bin"),
      key: "bin",
      render: (record) => {
        return record?.bin ? <span>{record.bin}</span> : <MinusOutlined />;
      },
      sorter: (a, b) => a.bin - b.bin,
      show: true,
    },
    {
      title: t("hardwareScreen.table.status"),
      key: "active",
      render: (record) => {
        return (
          <span style={style.dataContainer}>
            {record.active ? (
              <div style={style.statusActif}>{t("statuses.active")}</div>
            ) : (
              <div style={style.statusInactif}>{t("statuses.inactive")}</div>
            )}
          </span>
        );
      },
      sorter: (a, b) => a.active.length - b.active.length,
      show: true,
    },
    {
      title: t("hardwareScreen.table.actions"),
      key: "actions",
      render: (text, record) => (
        <ActionButtonWithChangeStatus
          record={record}
          onDelete={() => handleDeleteHardware(record.id)}
          onRowClick={() => handleSelectedRow(record)}
          onChangeStatus={() => handleChangeStatus(record)}
        />
      ),

      show: true,
    },
  ];
  const [filter, setFilter] = useState("");
  const [isMakingApiCall, setMakingApiCall] = useState(true);
  const [isHardwareDrawerVisible, setHardwareDrawerVisible] = useState(false);
  const [hardware, setHardware] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);

  const dispatch = useDispatch();
  const { hardwares } = useSelector((state) => state.hardwares);

  const fetchHardwares = () => {
    dispatch(getAllHardwares())
      .unwrap()
      .then(() => setMakingApiCall(false))
      .catch(() => setMakingApiCall(false));
  };

  useEffect(() => {
    fetchHardwares();
  }, [dispatch]);

  const filteredHardware = hardwares?.data?.filter((item) => {
    return (
      item?.name.toLowerCase()?.includes(filter.toLowerCase()) ||
      item.serialNumber.toLowerCase().includes(filter.toLowerCase()) ||
      item.kiosk?.name.toLowerCase().includes(filter.toLowerCase())
    );
  });

  const closeDrawer = () => {
    setHardwareDrawerVisible(false);
    setHardware(null);
  };
  const openDwawer = () => {
    setHardwareDrawerVisible(true);
  };

  const handleSelectedRow = (record) => {
    setHardware(record);
    setHardwareDrawerVisible(true);
  };

  const handleDeleteHardware = (id) => {
    dispatch(deleteHardware(id))
      .unwrap()
      .then(() => {
        message.success(t("messages.hardwareDeletedSuccessfully"));
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  const handleChangeStatus = (record) => {
    dispatch(changeHardwareStatus(record.id))
      .unwrap()
      .then(() => {
        message.success(t("messages.hardwareStatusUpdatedSuccessfully"));
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  const handleGenerateCsv = (event, done) => {
    const promise = async () => {

      //setCsvData(filteredHardware);
      setCsvHeaders([
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Serial Number",
          key: "serialNumber",
        },
        {
          label: "Kiosk name",
          key: "kiosk.name",
        },
        {
          label: "Stock",
          key: "stock",
        },
        {
          label: "bin",
          key: "bin",
        },
        {
          label: "status",
          key: "active",
        },
      ]);
    };

    promise().then(done());
  };

  return (
    <>
      <Header
        addButtonText={t("hardwareScreen.header.addHardware")}
        onAddButtonClick={openDwawer}
        onSearch={(e) => {
          setFilter(e.target.value);
        }}
        searchPlaceholder={t("hardwareScreen.header.search")}
        addButtonTextColor={"#ffffff"}
        onReload={fetchHardwares}
        showAddButton={true}
        BreadcrumbItems={[
          {
            title: t("hardwareScreen.header.title"),
          },
        ]}
        showBreadcrumb={true}
        showExportCSV={true}
        csvHeaders={csvHeaders}
        csvData={filteredHardware}
        generateCSVfn={handleGenerateCsv}
        csvDataMiddleFileName={"Hardware"}
      />
      <Card style={{ margin: 16, height: "85vh" }}>
        <CustomTableComponents
          isMakingApiCall={isMakingApiCall}
          columns={columns.filter((column) => column.show)}
          dataArray={filteredHardware}
          handleDoubleClickRow={(record) => handleSelectedRow(record)}
          handleSelectedRow={() => {}}
        />
      </Card>
      {isHardwareDrawerVisible && (
        <HardwareDrawer hardwareId={hardware?.id} onClose={closeDrawer} />
      )}
    </>
  );
};

export default Hardwares;
