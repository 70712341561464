import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";

const initialState = {
  selectedHardware: { loading: false, data: null },
  hardwares: { loading: false, data: [] },
};

export const getAllHardwares = createAsyncThunk(
  "Hardwares/getAllHardwares",
  async () => {
    try {
      const response = await axios.get(`/hardwares`);
      return response.data;
    } catch (error) {
      const customError = {
        name: error.name,
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const getHardwareById = createAsyncThunk(
  "Hardwares/getHardwareById",
  async (hardwareId) => {
    try {
      const response = await axios.get(`/hardware/${hardwareId}`);
      return response.data;
    } catch (error) {
      const customError = {
        name: error.name,
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const updateHardware = createAsyncThunk(
  "Hardwares/updateHardware",
  async (data) => {
    try {
      const response = await axios.put(`/hardware/${data.id}`, data.hardware);
      return response.data;
    } catch (error) {
      const customError = {
        name: error.name,
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const saveHardware = createAsyncThunk(
  "Hardwares/saveHardware",
  async (hardware) => {
    try {
      const response = await axios.post(`/hardware`, hardware);
      return response.data;
    } catch (error) {
      const customError = {
        name: error.name,
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const deleteHardware = createAsyncThunk(
  "Hardwares/deleteHardware",
  async (hardwareId) => {
    try {
      await axios.delete(`/hardware/${hardwareId}`);
      return hardwareId;
    } catch (error) {
      const customError = {
        name: error.name,
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const changeHardwareStatus = createAsyncThunk(
  "Hardwares/changeHardwareStatus",
  async (hardwareId) => {
    try {
      const response = await axios.put(`/hardware/change-status/${hardwareId}`);
      return response.data;
    } catch (error) {
      const customError = {
        name: error.name,
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const hardwareSlice = createSlice({
  name: "Hardwares",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllHardwares.fulfilled]: (state, action) => {
      state.hardwares.loading = false;
      state.hardwares.data = action.payload;
    },
    [getAllHardwares.rejected]: (state) => {
      state.hardwares.loading = false;
    },
    [getAllHardwares.pending]: (state) => {
      state.hardwares.loading = true;
    },

    [getHardwareById.fulfilled]: (state, action) => {
      state.selectedHardware.loading = false;
      state.selectedHardware.data = action.payload;
    },
    [getHardwareById.pending]: (state) => {
      state.selectedHardware.loading = true;
    },
    [getHardwareById.rejected]: (state) => {
      state.selectedHardware.loading = false;
    },

    [updateHardware.fulfilled]: (state, action) => {
      state.hardwares.loading = false;
      state.hardwares.data = state.hardwares.data.map((hardware) =>
        hardware.id === action.payload.id ? action.payload : hardware
      );
    },
    [updateHardware.pending]: (state) => {
      state.hardwares.loading = true;
    },
    [updateHardware.rejected]: (state) => {
      state.hardwares.loading = false;
    },

    [saveHardware.fulfilled]: (state, action) => {
      state.hardwares.loading = false;
      state.hardwares.data.push(action.payload);
    },
    [saveHardware.pending]: (state) => {
      state.hardwares.loading = true;
    },
    [saveHardware.rejected]: (state) => {
      state.hardwares.loading = false;
    },

    [deleteHardware.pending]: (state) => {
      state.hardwares.loading = true;
    },
    [deleteHardware.fulfilled]: (state, action) => {
      state.hardwares.data = state.hardwares.data.filter(
        (hardware) => hardware.id !== action.payload
      );
    },
    [deleteHardware.rejected]: (state) => {
      state.hardwares.loading = false;
    },

    [changeHardwareStatus.fulfilled]: (state, action) => {
      state.hardwares.data = state.hardwares.data.map((hardware) =>
        hardware.id === action.payload.id ? action.payload : hardware
      );
      state.hardwares.loading = false;
    },
    [changeHardwareStatus.pending]: (state) => {
      state.hardwares.loading = true;
    },
    [changeHardwareStatus.rejected]: (state) => {
      state.hardwares.loading = false;
    },
  },
});

export default hardwareSlice.reducer;
