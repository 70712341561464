/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import { Button, Form, message, Col, Row } from "antd";

import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import CheckCodeSvg from "../../icons/svgImages/CheckCodeSvg";
import { checkPasswordResetCode } from "../../redux/authSlice";

const CheckResetCodeValidation = () => {
  const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;

  const [makingApiCall, setMakingApiCall] = useState(false);
  const location = useLocation();
  const email = location?.state?.email;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onFinish = (values) => {
    if (values.code.length !== 6 || !email) {
      message.error("The code should be 6 digits long");
      return;
    }
    setMakingApiCall(true);
    dispatch(
      checkPasswordResetCode({
        email: email.trim().toLowerCase(),
        code: values.code,
      })
    )
      .unwrap()
      .then(() => {
        message.success("Code is valid");
        navigate("/app/reset-password", {
          state: { email, code: values.code },
        });
      })
      .catch((e) => {
        if (e.message === "INVALID_CODE") {
          message.error("The code you entered is invalid");
        } else if (e.message === "CODE_EXPIRED") {
          message.error("The code you entered has expired");
        } else {
          message.error(
            "Error while checking your code, please try again later"
          );
        }
      })
      .finally(() => {
        setMakingApiCall(false);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (!email) {
      navigate("/app/login");
    }
  }, [email]);

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: "100vh" }}
    >
      <Col xs={{ span: 12, offset: 1 }} lg={{ span: 10, offset: 1 }}>
        <CheckCodeSvg
          fillColor={primaryColor || "#ffa500"}
          height={500}
          width={700}
        />
      </Col>
      <Col xs={{ span: 12, offset: 1 }} lg={{ span: 6, offset: 1 }}>
        <Form
          name="login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <h1 style={{ textAlign: "center" }}>Check reset code</h1>
          <p style={{ color: "#6c757d" }}>
            Please enter the 6-digit code that was sent to your email address to
            reset your password. This code is valid for a limited time only, so
            please enter it as soon as possible to proceed with resetting your
            password.
          </p>
          <Form.Item
            label="Code"
            name="code"
            rules={[{ required: true, message: "Please input your code" }]}
            wrapperCol={{ offset: 2, span: 22 }}
          >
            <ReactCodeInput
              fieldHeight={30}
              fieldWidth={35}
              autoFocus
              fields={6}
              type="number"
            />
          </Form.Item>

          <Form.Item style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit">
              {makingApiCall ? "Checking..." : "Check"}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default CheckResetCodeValidation;
