import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./en/en.json";
import translationIT from "./it/it.json";
import store from "../redux/store";

const resources = {
  en: {
    translation: translationEN,
  },
  it: {
    translation: translationIT,
  },
};

// Access the selected language from Redux state
const getUserSelectedLanguage = () => {
  const state = store.getState();
  return state?.users?.profile?.user?.preferredLanguage || "en"; // Default language is English
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: getUserSelectedLanguage(), // Set the initial language based on Redux state
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  })
  .then(() => {
    console.log("i18n initialized successfully");
  })
  .catch((error) => {
    console.error("i18n initialization failed:", error);
  });

// Listen to Redux state changes and update the language dynamically
store.subscribe(() => {
  const newLanguage = getUserSelectedLanguage();
  if (i18n.language !== newLanguage) {
    i18n.changeLanguage(newLanguage);
  }
});

export default i18n;
