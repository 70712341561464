import React, { useState } from "react";
import { Drawer, Form, Select, Button, Spin, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const today = dayjs().startOf("day");
const tomorrow = dayjs().add(1, "day").startOf("day");

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

const FilteringDrawer = ({
  closeFilteringDrawer,
  isFilteringDrawerOpened,
  resetFilter,
  onSave,
  title,
  loading,
  formName,
  selectLabel,
  selectValue,
  selectKey,
  data,
  optionValue,
  displayOneDropdown = true,
  dateRange,
  displayDateRange = true,
  setDateRange,
  setSelectedOption,
  selectedOption,
  fieldsOfDropdowns,
  selectedOptionOfDropdownFields,
  setSelectedOptionOfDropdownFields,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onDrawerClose = () => {
    // close drawer
    closeFilteringDrawer();
  };

  const onDateRangeChange = (value) => {
    setDateRange(value);
  };
  const onResetForm = () => {
    form.resetFields();
    displayDateRange && setDateRange([today, tomorrow]);
    displayOneDropdown && setSelectedOption(null);
    fieldsOfDropdowns?.reduce((acc, field) => {
      setSelectedOptionOfDropdownFields(field.key, null);
    }, {});
    resetFilter();
  };
  return (
    <Drawer
      form={form}
      title={title ? title : "Filter"}
      placement="right"
      closable={false}
      onClose={onDrawerClose}
      width={400}
      open={isFilteringDrawerOpened}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          name={formName}
          layout="vertical"
          initialValues={{}}
          onFinish={(values) => onSave(values)}
          onFinishFailed={() => {}}
          autoComplete="off"
          onValuesChange={() => {}}
        >
          {displayOneDropdown && (
            <Form.Item label={selectLabel} name="device">
              <Select
                showarrow="true"
                showSearch
                optionFilterProp="filterBy"
                allowClear
                optionLabelProp="label"
                value={selectedOption}
                onChange={(value) => setSelectedOption(value)}
              >
                {data.map((item) => (
                  <Select.Option
                    key={selectKey ? item[selectKey] : item[selectValue]}
                    filterBy={`${item[selectValue]}`}
                    label={`${item[selectValue]}`}
                    value={item?.id}
                  >
                    <div>{item[optionValue]}</div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {displayDateRange && (
            <Form.Item
              label={t("validatorLogScreen.filterDrawer.dateRange")}
              name="date"
            >
              <RangePicker
                onChange={onDateRangeChange}
                initialValues={dateRange}
                format={dateFormat}
                style={{ width: "100%" }}
                allowClear={false}
                placeholder={[
                  t("drawerFilter.startDate"),
                  t("drawerFilter.endDate"),
                ]}
              />
            </Form.Item>
          )}
          {fieldsOfDropdowns?.map((field) => (
            <Form.Item key={field.key} label={field.label} name={field.id}>
              <Select
                showSearch
                optionFilterProp="children"
                allowClear
                value={selectedOptionOfDropdownFields[field.key]}
                onChange={(value) =>
                  setSelectedOptionOfDropdownFields(field.key, value)
                }
              >
                {field.options.map((option) => {
                  // Check if the option is an object or a string
                  if (typeof option === "object") {
                    return (
                      <Select.Option
                        key={option[field.optionValue]}
                        value={option[field.optionValue]}
                      >
                        {option[field.optionLabel]}
                      </Select.Option>
                    );
                  } else {
                    return (
                      <Select.Option key={option} value={option}>
                        {option}
                      </Select.Option>
                    );
                  }
                })}
              </Select>
            </Form.Item>
          ))}
          <Form.Item style={{ float: "right" }}>
            <Button
              type="default"
              style={{ marginBottom: 32, marginRight: 8 }}
              onClick={() => closeFilteringDrawer()}
            >
              {t("drawerButtons.cancel")}
            </Button>
            <Button
              type="primary"
              style={{ marginBottom: 32 }}
              htmlType="submit"
            >
              {t("drawerButtons.filter")}
            </Button>
          </Form.Item>
          <Form.Item style={{ float: "left" }}>
            <Button
              type="default"
              styles={{ backgroundColor: "red" }}
              style={{ marginBottom: 32, marginRight: 8 }}
              onClick={() => onResetForm()}
            >
              {t("drawerButtons.resetFilter")}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default FilteringDrawer;
