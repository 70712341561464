import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from '../axios';

const initialState = {
    cards: [],
};

export const getCards = createAsyncThunk('Card/getCards', async () => {
    try {
        const response = await axios.get(`/card`);
        return response.data;
    } catch (error) {
        const customError = {
            name: error.code,
            message: error.message,
        };
        throw customError;
    }
});

export const getCardsBetweenDate = createAsyncThunk("card/getCardsBetweenDate", async (data) => {
    try {
      const response = await axios.patch(`/card/filter`, data);
      return response.data;
    } catch (error) {
      const customError = {
        name: error.code,
        message: error.message,
      };
      throw customError;
    }
  });

export const cardSlice = createSlice({
    name: 'Card',
    initialState,
    reducers:{},
    extraReducers:{
        [getCards.fulfilled]: (state, action) => {
            state.cards = action.payload;
        },
        [getCardsBetweenDate.fulfilled]: (state, action) => {
            state.cards = action.payload;
        },
    }
});

export default cardSlice.reducer;
