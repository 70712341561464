import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";

const initialState = {
  users: { loading: false, usersList: [] },
  SelectedUser: { loading: false, user: null },
  profile: { loading: false, user: null },
};

export const getAllUsers = createAsyncThunk(
  "User/getAllUsers",
  async (data) => {
    try {
      console.log("data", data);
      const response = await axios.get(`/users`, data);
      return response.data;
    } catch (error) {
      const customError = {
        name: "Custom axios error",
        message: error.response.data.message,
        /* data: error.response.data, */
      };
      throw customError;
    }
  }
);

export const addNewUser = createAsyncThunk("User/addNewUser", async (data) => {
  try {
    const response = await axios.post(`users`, data.user);
    return response.data;
  } catch (error) {
    const customError = {
      name: "Custom axios error",
      message: error.response.data.message,
      /* data: error.response.data, */
    };
    throw customError;
  }
});

export const getUserById = createAsyncThunk(
  "User/getUserById",
  async (data) => {
    try {
      const response = await axios.get(`users/${data.userId}`);
      return response.data;
    } catch (error) {
      const customError = {
        name: "Custom axios error",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const deleteUser = createAsyncThunk("User/deleteUser", async (data) => {
  try {
    await axios.delete(`users/${data.userId}`);
    return data.userId;
  } catch (error) {
    const customError = {
      name: "Custom axios error",
      message: error.response.data.message,
    };
    throw customError;
  }
});

export const updateUser = createAsyncThunk("User/updateUser", async (data) => {
  try {
    const response = await axios.put(`users/${data.userId}`, data.user);
    return response.data;
  } catch (error) {
    const customError = {
      name: "Custom axios error",
      message: error.response.data.message,
    };
    throw customError;
  }
});

export const getUserProfile = createAsyncThunk(
  "User/getUserProfile",
  async () => {
    try {
      const response = await axios.get(`user/me`);
      return response.data;
    } catch (error) {
      const customError = {
        name: "Custom axios error",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const getAllUsersByRole = createAsyncThunk(
  "User/getAllUsersByRole",
  async (data) => {
    try {
      console.log("data", data);
      const response = await axios.get(`users/role/${data.role}`);
      return response.data;
    } catch (error) {
      const customError = {
        name: "Custom axios error",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const addKioskOrValidatorUser = createAsyncThunk(
  "User/addKioskOrValidatorUser",
  async (data) => {
    try {
      const response = await axios.post(`add-kiosk-user`, data);
      return response.data;
    } catch (error) {
      const customError = {
        name: "Custom axios error",
        message: error.response.data.message,
        /* data: error.response.data, */
      };
      throw customError;
    }
  }
);

export const changeUserStatus = createAsyncThunk(
  "User/changeUserStatus",
  async (data) => {
    try {
      const response = await axios.patch(`users/${data.id}`, data);
      return response.data;
    } catch (error) {
      const customError = {
        name: "Custom axios error",
        message: error.response.data.message,
        /* data: error.response.data, */
      };
      throw customError;
    }
  }
);

export const changePreferredLanguage = createAsyncThunk(
  "Auth/changePreferredLanguage",
  async (language) => {
    try {
      const response = await axios.patch(
        `/users/preferred-language/${language}`
      );
      return response.data;
    } catch (error) {
      const customError = {
        name: "Error change preferred language",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    clearProfile: (state) => {
      state.profile = { loading: false, user: null };
    },
  },
  extraReducers: {
    [getAllUsers.pending]: (state) => {
      state.users.loading = true;
    },
    [getAllUsers.rejected]: (state) => {
      state.users.loading = false;
    },
    [getAllUsers.fulfilled]: (state, action) => {
      state.users.loading = false;
      state.users.usersList = Object.values(action.payload);
    },
    [addNewUser.pending]: (state) => {
      state.users.loading = true;
    },
    [addNewUser.rejected]: (state) => {
      state.users.loading = false;
    },
    [addNewUser.fulfilled]: (state, action) => {
      state.users.loading = false;
      state.users.usersList.push(action.payload);
    },
    [getUserById.pending]: (state) => {
      state.SelectedUser.loading = true;
    },
    [getUserById.rejected]: (state) => {
      state.SelectedUser.loading = false;
    },
    [getUserById.fulfilled]: (state, action) => {
      state.SelectedUser.loading = false;
      state.SelectedUser.user = action.payload;
    },
    [deleteUser.pending]: (state) => {
      state.users.loading = true;
    },
    [deleteUser.rejected]: (state) => {
      state.users.loading = false;
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.users.loading = false;
      state.users.usersList = state.users.usersList.filter((item) => {
        return item.id !== action.payload;
      });
    },
    [updateUser.pending]: (state) => {
      state.users.loading = true;
    },
    [updateUser.rejected]: (state) => {
      state.users.loading = false;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.users.loading = false;
      state.users.usersList = state.users.usersList.map((item) => {
        if (item.id === action.payload.id) {
          item = action.payload;
          return item;
        }
        return item;
      });
    },
    [getUserProfile.pending]: (state) => {
      state.profile.loading = true;
    },
    [getUserProfile.rejected]: (state) => {
      state.profile.loading = false;
    },
    [getUserProfile.fulfilled]: (state, action) => {
      state.profile.loading = false;
      state.profile.user = action.payload;
    },
    [getAllUsersByRole.pending]: (state) => {
      state.users.loading = true;
    },
    [getAllUsersByRole.rejected]: (state) => {
      state.users.loading = false;
    },
    [getAllUsersByRole.fulfilled]: (state, action) => {
      state.users.loading = false;
      state.users.usersList = Object.values(action.payload);
    },
    [addKioskOrValidatorUser.pending]: (state) => {
      state.users.loading = true;
    },
    [addKioskOrValidatorUser.rejected]: (state) => {
      state.users.loading = false;
    },
    [addKioskOrValidatorUser.fulfilled]: (state, action) => {
      state.users.loading = false;
      state.users.usersList.push(action.payload);
    },
    [changeUserStatus.pending]: (state) => {
      state.users.loading = true;
    },
    [changeUserStatus.rejected]: (state) => {
      state.users.loading = false;
    },
    [changeUserStatus.fulfilled]: (state, action) => {
      state.users.loading = false;
      state.users.usersList = state.users.usersList.map((item) => {
        if (item.id === action.payload.id) {
          item = action.payload;
          return item;
        }
        return item;
      });
    },
    [changePreferredLanguage.pending]: (state) => {
      state.profile.loading = true;
    },
    [changePreferredLanguage.rejected]: (state) => {
      state.profile.loading = false;
    },
    [changePreferredLanguage.fulfilled]: (state, action) => {
      state.profile.loading = false;
      state.profile.user = action.payload;
    },
  },
});

export const { clearProfile } = userSlice.actions;

export default userSlice.reducer;
