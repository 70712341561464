import React, { useEffect, useState } from "react";
import { Spin, Row, Col, message } from "antd";
import KioskCardComponent from "../../global/KioskCardComponent";
import { LoadingOutlined } from "@ant-design/icons";
import Header from "../../global/Header/Header";
import KioskDrawer from "../Kiosks/KioskDrawer";
import { useDispatch, useSelector } from "react-redux";
import { getAllKiosks } from "../../redux/kiosksSlice";
import { useTranslation } from "react-i18next";

const Kiosks = () => {
  const { t } = useTranslation();
  const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;

  const { kiosks: kiosksArray } = useSelector((state) => state.kiosks);

  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState("");
  const [modalKioskOpened, setModalKioskOpened] = useState(false);

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };
  const filteredKiosks = kiosksArray?.kiosksList?.filter(
    (kiosk) =>
      kiosk?.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      kiosk?.referenceNumber
        .toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase()) ||
      kiosk.id.toString().toLowerCase().includes(searchValue.toLowerCase())
  ).sort((a, b) => {
    if (a.type === "KIOSK_STATION" && b.type !== "KIOSK_STATION") {
      return -1; 
    } else if (a.type !== "KIOSK_STATION" && b.type === "KIOSK_STATION") {
      return 1; 
    } else {
      return 0; 
    }
  });

  const onOpenModal = () => {
    setModalKioskOpened(true);
  };

  const onCloseModal = () => {
    setModalKioskOpened(false);
  };

  const fetchKiosks = async () => {
    dispatch(getAllKiosks())
      .unwrap()
      .then(() => {})
      .catch(() => {
        message.error("Error while fetching kiosks");
      });
  };

  useEffect(() => {
    fetchKiosks();
  }, [dispatch]);

  return (
    <Spin
      spinning={kiosksArray?.loading}
      size="large"
      indicator={<LoadingOutlined style={{ fontSize: 54 }} spin />}
      tip={
        <div>
          <h2>{t("loading")}</h2>
        </div>
      }
    >
      <Header
        addButtonText={t("kioskScreen.header.addKiosk")}
        onAddButtonClick={() => onOpenModal()}
        onSearch={onSearch}
        searchPlaceholder={t("kioskScreen.header.search")}
        addButtonBackgroundColor={primaryColor} // #ecefff
        addButtonBorderColor={primaryColor}
        addButtonTextColor={"#ffffff"}
        onReload={fetchKiosks}
        showAddButton={true}
        BreadcrumbItems={[
          {
            title: t("kioskScreen.header.title"),
          },
        ]}
        showBreadcrumb={true}
      />
      <Row style={{ margin: 20 }}>
        {filteredKiosks.map((kiosk) => (
          <Col key={kiosk.id} xs={24} sm={24} md={12} lg={8} xl={6}>
            <KioskCardComponent key={kiosk?.id} kiosk={kiosk} />
          </Col>
        ))}
      </Row>
      {modalKioskOpened && (
        <KioskDrawer kioskId={null} onClose={onCloseModal} />
      )}
    </Spin>
  );
};

export default Kiosks;
