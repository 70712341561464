import axios from "axios";
import env from "../Utils/Constants/env";
export const baseURL = `${env.baseUri}`;

const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": ["POST", "GET", "PUT", "DELETE"],
  },
});

export default instance;