import React, { useState } from "react";
import { Button, Form, Input, message, Col, Row } from "antd";

import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import ForgetPasswordSvg from "../../icons/svgImages/ForgetPasswordSvg";
import { forgetPasswordRequest } from "../../redux/authSlice";

const ForgetPasswordRequest = () => {
	const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [makingApiCall, setMakingApiCall] = useState(false);

  const onFinish = (values) => {
    setMakingApiCall(true);
    dispatch(forgetPasswordRequest(values.email))
      .unwrap()
      .then(() => {
        message.success("Reset code sent to your email");
        navigate("/app/check-reset-code", { state: { email: values.email } });
      })
      .catch((e) => {
        if (e.message === "USER_DOES_NOT_EXIST") {
          message.error("User with this email does not exist");
        } else {
          message.error(
            "Error while requesting a reset password, please try again later"
          );
        }
      })
      .finally(() => {
        setMakingApiCall(false);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: "100vh" }}
    >
      <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 0 }} lg={{ span: 10 }}>
        <ForgetPasswordSvg
          fillColor={primaryColor || "#ffa500"}
          height={500}
          width={600}
        />
      </Col>
      <Col xs={{ span: 12, offset: 1 }} lg={{ span: 6, offset: 1 }}>
        <Form
          name="login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <h1 style={{ textAlign: "center" }}>Forget Password</h1>
          <p style={{ color: "#6c757d" }}>
            Please enter your email address below and click the "Submit" button
            to receive a 6-digit code to reset your password.
          </p>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Email is required" },
              {
                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: "Please enter a valid email address",
              },
            ]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit" disabled={makingApiCall}>
              {makingApiCall ? "Sending request..." : "Send code"}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default ForgetPasswordRequest;
