import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HttpStatus from "http-status-codes";
import { isCancel } from "axios";
import { setToken, setLoggedIn } from "../redux/authSlice";

import axiosInstance from "./index";
import {
  showAuthorizationFailedErrorMessage,
  showConnectivityErrorMessage,
  showHasReferenceErrorMessage,
  showRequestLargerThanMaxSizeErrorMessage,
  showUnhandledErrorMessage,
} from "../Utils/Imports/ShowErrors";

const AxiosInterceptor = () => {
  const { auth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _retry = useRef(false);
  useEffect(() => {

    const reqInterceptor = (config) => {
      // if auth.tokne is not null and the request is not for login or refresh-token
      if (
        auth.token &&
        config.url !== "login" &&
        config.url !== "/refresh-token"
      ) {
        // eslint-disable-next-line dot-notation
        config.headers["Authorization"] = `Bearer ${auth.token}`;
      }
      return config;
    };

    const reqErrInterceptor = (error) => {
      return Promise.reject(error);
    };

    const resInterceptor = (res) => {
      return res;
    };
    const resErrInterceptor = async (err) => {
      const originalConfig = err.config;
      if (!isCancel(err)) {
        const status = err?.response?.status;
        if (status == null) {
          showConnectivityErrorMessage();
        } else if (
          status === 401 &&
          originalConfig.url !== "/login" &&
          originalConfig.url !== "/refresh-token" &&
          _retry.current === false
        ) {
          // REFRESH TOKEN
          _retry.current = true;
          try {
            // eslint-disable-next-line prefer-destructuring
            const username = auth.loggedIn.username;
            // eslint-disable-next-line prefer-destructuring
            const refreshToken = auth.loggedIn.refreshToken;
            // ?check if ex
            const response = await axiosInstance.post(
              "/refresh-token",
              {
                username,
                refreshToken,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST",
                },
              }
            );
            dispatch(setToken(response.data.accessToken));
            dispatch(setLoggedIn(response.data));
            _retry.current = false;
            return axiosInstance(originalConfig);
          } catch (_error) {
            console.error("refresh token failed and navigate to login");
            // ? clear the token and loggedIn state
            dispatch(setToken(""));
            dispatch(setLoggedIn([]));
            navigate("/app/login", { replace: true });

            return Promise.reject(_error);
          }
        } else if (status === HttpStatus.FORBIDDEN) {
          showAuthorizationFailedErrorMessage();
        } else if (status === HttpStatus.CONFLICT) {
          // skip
          // } else if ((status === HttpStatus.NOT_FOUND) && (error?.request?.responseURL?.endsWith('/users/password-change'))) {
        } else if (status === HttpStatus.NOT_FOUND) {
          // skip
        } else if (status === HttpStatus.LOCKED) {
          showHasReferenceErrorMessage();
        } else if (status === HttpStatus.REQUEST_TOO_LONG) {
          showRequestLargerThanMaxSizeErrorMessage();
        } else {
          showUnhandledErrorMessage();
        }
      }
      return Promise.reject(err);
    };
    const reqUseInterceptor = axiosInstance.interceptors.request.use(
      reqInterceptor,
      reqErrInterceptor
    );
    const resUseInterceptor = axiosInstance.interceptors.response.use(
      resInterceptor,
      resErrInterceptor
    );

    return () => {
      axiosInstance.interceptors.request.eject(reqUseInterceptor);
      axiosInstance.interceptors.response.eject(resUseInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, auth.token]);
  return "";
};

export default AxiosInterceptor;
