import React, { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ShowConfirmClose from "../Modals/ShowConfirmClose";
import { addKiosk, getKioskById, updateKiosk } from "../../redux/kiosksSlice";
import { Drawer, Spin, message } from "antd";
import KioskForm from "./KioskForm";
import { useTranslation } from "react-i18next";

function KioskDrawer({ kioskId, onClose }) {
  const { t } = useTranslation();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const dispatch = useDispatch();
  const [makingApiCall, setMakingApiCall] = useState(false);
  const [kiosk, setKiosk] = useState(null);

  const onSave = (values) => {
    saveKiosk(values);
  };

  const handleClose = useCallback(
    (hasUnsavedChanges) => {
      if (hasUnsavedChanges) {
        ShowConfirmClose(() => {
          onClose();
        });
      } else {
        onClose();
      }
    },
    [onClose]
  );

  const handleDirty = useCallback(() => {
    setUnsavedChanges(true);
  }, []);

  const saveKiosk = async (values) => {
    const action = kioskId
      ? updateKiosk({
          ...values,
          position: {
            latitude: values.latitude,
            longitude: values.longitude,
          },
          active: kiosk.active,
          id: kioskId,
        })
      : addKiosk({
          ...values,
          position: {
            latitude: values.latitude,
            longitude: values.longitude,
          },
        });
    dispatch(action)
      .unwrap()
      .then(() => {
        message.success(t("kioskScreen.drawer.kioskSavedSuccessfully"));
        setUnsavedChanges(false);
      })
      .catch((e) => {
        if (
          e.message === "KIOSK_ALREADY_EXISTS_WITH_REFERENCE_NUMBER_AND_NAME"
        ) {
          message.error(t("kioskScreen.drawer.kioskAlreadyExistsWithSameName"));
        } else if (e.message === "KIOSK_ALREADY_EXISTS_WITH_REFERENCE_NUMBER") {
          message.error(
            t("kioskScreen.drawer.kioskAlreadyExistsWithSameReferenceNumber")
          );
        } else {
          message.error(t("kioskScreen.drawer.couldNotSaveKiosk"));
        }
      })
      .finally(() => {
        onClose();
        setMakingApiCall(false);
      });
  };

  const onSaveFailed = (error) => {
    console.log("Failed:", error);
  };

  useEffect(() => {
    const abortController = new AbortController();
    if (kioskId) {
      setMakingApiCall(true);
      dispatch(getKioskById(kioskId))
        .unwrap()
        .then((originalWayPoint) => {
          setKiosk(originalWayPoint);
        })
        .catch((e) => {
          if (e.message === "KIOSK_NOT_FOUND") {
            message.error(t("kioskScreen.drawer.kioskNotFound"));
          }
        })
        .finally(() => {
          setMakingApiCall(false);
        });
    } else {
      setKiosk(null);
    }
    return () => abortController.abort();
  }, [dispatch, kioskId]);

  return (
    <Drawer
      width={500}
      title={
        !kioskId
          ? t("kioskScreen.drawer.addKiosk")
          : t("kioskScreen.drawer.editKiosk")
      }
      placement="right"
      onClose={() => handleClose(unsavedChanges)}
      open
    >
      <Spin
        size="large"
        spinning={makingApiCall}
        style={{ marginTop: 64 }}
        delay={500}
      >
        {!makingApiCall && (
          <KioskForm
            kiosk={kiosk}
            onSave={onSave}
            onSaveFailed={onSaveFailed}
            onCancel={handleClose}
            onDirty={handleDirty}
            unsavedChanges={unsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        )}
      </Spin>
    </Drawer>
  );
}

export default KioskDrawer;
