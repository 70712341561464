import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";

// Define the async thunk for fetching the data
export const getAllMaintenanceLogs = createAsyncThunk(
  "maintenanceLog/getAllLogs",
  async (payload) => {
    try {
      const response = await axios.patch("/maintenance-logs", payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getMaintenanceLogsByFilter = createAsyncThunk(
  "maintenanceLog/getLogsByFilter",
  async (payload) => {
    try {
      const response = await axios.patch("/maintenance-logs/filter", payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const maintenanceLogSlice = createSlice({
  name: "maintenanceLog",
  initialState: {
    maintenanceLogs: { loading: false, data: [] },
  },
  reducers: {},
  extraReducers: {
    [getAllMaintenanceLogs.pending]: (state) => {
      state.maintenanceLogs.loading = true;
    },
    [getAllMaintenanceLogs.rejected]: (state, action) => {
      state.maintenanceLogs.loading = false;
    },
    [getAllMaintenanceLogs.fulfilled]: (state, action) => {
      state.maintenanceLogs.data = action.payload;
      state.maintenanceLogs.loading = false;
    },
    [getMaintenanceLogsByFilter.pending]: (state) => {
      state.maintenanceLogs.loading = true;
    },
    [getMaintenanceLogsByFilter.rejected]: (state, action) => {
      state.maintenanceLogs.loading = false;
    },
    [getMaintenanceLogsByFilter.fulfilled]: (state, action) => {
      state.maintenanceLogs.data = action.payload;
      state.maintenanceLogs.loading = false;
    },
  },
});

export default maintenanceLogSlice.reducer;
