import { style } from "../Style/Style";

export const getSpanStyleForRole = (role) => {
  switch (role) {
    case "TECHNICIAN_ROLE":
      return style.statusInProgress;
    case "ADMIN_ROLE":
      return style.statusAccepted;
    case "USER_ROLE":
      return style.statusTimedOut;
    case "SUPER_ADMIN_ROLE":
      return style.statusCompleted;
    case "KIOSK_ROLE":
      return style.statusRefunded;
    case "VALIDATOR_ROLE":
      return style.statusRequested;
    default:
      return null;
  }
};

export const getSpanStyleForHardware = (hardwareNameValue) => {
  switch (hardwareNameValue) {
    case "CARD_DISPENSER":
      return style.statusInProgress;
    case "PRINTER":
      return style.statusAccepted;
    case "CASH_ACCEPTOR":
      return style.statusTimedOut;
    case "COIN_ACCEPTOR":
      return style.statusCompleted;
    case "PAYMENT_MODULE":
      return style.statusRefunded;
    case "VALIDATOR_ROLE":
      return style.statusRequested;
    case "SERVER":
      return style.statusRequested;
    default:
      return style.statusInProgress;
  }
};
