import { Button, Form, Input, Select } from "antd";
import React, { useState, useEffect } from "react";

import { useLoadScript } from "@react-google-maps/api";
import MapWithAutocomplete from "../../global/GoogleMaps/MapWithAutocomplete";
import { useTranslation } from "react-i18next";

const libraries = ["core", "places", "geocoding", "routes", "geometry"];

const KioskForm = ({
  onSave,
  onSaveFailed,
  kiosk,
  onCancel,
  onDirty,
  unsavedChanges,
  setUnsavedChanges,
}) => {
  const { t } = useTranslation();
  const [selectedLocation, setSelectedLocation] = useState(kiosk ? 
    {
    lat: kiosk?.position?.latitude,
    lng: kiosk?.position?.longitude,
    } : null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  useEffect(() => {
    if (kiosk) {
      setSelectedLocation({
        lat: kiosk?.position?.latitude,
        lng: kiosk?.position?.longitude,
      });
    }
  }, [kiosk]);

  return (
    <Form
      name="Kiosk"
      layout="vertical"
      autoComplete="off"
      initialValues={kiosk}
      onFinish={(values) =>
        onSave({
          ...values,
          id: kiosk?.id,
          latitude: selectedLocation.lat,
          longitude: selectedLocation.lng,
        })
      }
      onFinishFailed={onSaveFailed}
      onValuesChange={() => onDirty()}
    >
      <Form.Item
        label={t("kioskScreen.form.kioskName")}
        name="name"
        rules={[
          {
            required: true,
            message: t("kioskScreen.form.pleaseEnterKioskName"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("kioskScreen.form.kioskSerialNumber")}
        name="serialNumber"
        rules={[
          {
            required: true,
            message: t("kioskScreen.form.pleaseEnterKioskSerialNumber"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("kioskScreen.form.kioskType")}
        name="type"
        rules={[
          {
            required: true,
            message: t("kioskScreen.form.pleaseSelectKioskType"),
          },
        ]}
      >
        <Select
          placeholder={t("kioskScreen.form.pleaseSelectKioskType")}
          allowClear
          style={{ width: "100%" }}
          disabled={kiosk !== null}
        >
          <Select.Option value="KIOSK_STATION">Kiosk Station</Select.Option>
          <Select.Option value="PORTABLE_KIOSK">Portable Kiosk</Select.Option>
          <Select.Option value="HANDHELD_KIOSK">Handheld Kiosk</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={t("kioskScreen.form.position")}
        name="position"
        rules={[
          {
            validator: (_, value) => {
              if (!value) {
                if (!selectedLocation) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject(
                    t("kioskScreen.form.pleaseSelectPosition")
                  );
                }
              }
              return Promise.resolve();
            },
          },
        ]}
        required
      >
        {isLoaded && process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? (
          <MapWithAutocomplete
            setSearchInput={setSearchInput}
            setSelectedLocation={setSelectedLocation}
            setUnsavedChanges={setUnsavedChanges}
            autocomplete={autocomplete}
            selectedLocation={selectedLocation}
            setAutocomplete={setAutocomplete}
            searchInput={searchInput}
          />
        ) : (
          <p>Loading...</p>
        )}
      </Form.Item>
      <Form.Item style={{ float: "right" }}>
        <Button
          type="default"
          style={{ marginBottom: 32, marginRight: 8 }}
          onClick={() => onCancel(unsavedChanges)}
        >
          {t("kioskScreen.form.cancel")}
        </Button>
        <Button
          type="primary"
          style={{ marginBottom: 32 }}
          htmlType="submit"
          disabled={!unsavedChanges}
        >
          {!kiosk ? t("kioskScreen.form.save") : t("kioskScreen.form.update")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default KioskForm;
