import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllKiosks } from "../../redux/kiosksSlice";
import { getAllValidators } from "../../redux/validatorSlice";
import { Button, Divider, Form, Select, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { Empty } from "antd"; // Import the Empty component if you want to use a built-in icon

const KioskAndValidatorUserForm = ({
  type,
  onCancel,
  onDirty,
  unsavedChanges,
  onSave,
  translatedType,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { validators } = useSelector((state) => state.validators);
  const { loading, kiosksList } = useSelector((state) => state.kiosks?.kiosks);

  const [machines, setMachines] = useState([]);
  const fetchDevicesDependOnType = () => {
    switch (type) {
      case "KioskUser":
        return fetchAllKiosks();
      case "ValidatorUser":
        return fetchAllValidators();
      default:
        return;
    }
  };

  const fetchAllKiosks = () => {
    dispatch(getAllKiosks());
  };
  const fetchAllValidators = () => {
    dispatch(getAllValidators());
  };
  useEffect(() => {
    fetchDevicesDependOnType();
    if (type === "KioskUser") {
      setMachines(kiosksList);
    }
    if (type === "ValidatorUser") {
      setMachines(validators?.data);
    }
  }, [dispatch, type]);
  return (
    <Spin spinning={type === "KioskUser" ? loading : validators?.loading}>
      <Form
        layout="vertical"
        name="userDeviceForm"
        onFinish={(values) => {
          onSave({
            ...values,
            role: type === "KioskUser" ? "KIOSK_ROLE" : "VALIDATOR_ROLE",
          });
        }}
        onValuesChange={() => onDirty()}
      >
        <Form.Item
          label={t("kioskAndValidatorUsersScreen.form.relatedMachineId")}
          name="relatedMachineId"
          rules={[
            {
              required: true,
              message: t(
                "kioskAndValidatorUsersScreen.form.pleaseEnterRelatedMachineId"
              ),
            },
          ]}
        >
          <Select
            size="medium"
            showArrow
            showSearch
            optionFilterProp="filterBy"
            allowClear
            optionLabelProp="label"
            // ? translate the noDataText and keep the noDataText as a key
            notFoundContent={
              <Empty key="noDataText" description={t("noData")} />
            }
          >
            {machines?.map((m) => (
              <Select.Option
                key={m?.id}
                filterBy={
                  type === "KioskUser" ? m?.referenceNumber : m?.serialNumber
                }
                value={m?.id}
                label={
                  type === "KioskUser" ? m?.referenceNumber : m?.serialNumber
                }
              >
                {type === "KioskUser" ? m?.referenceNumber : m?.serialNumber}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Divider />
        <Form.Item style={{ float: "right" }}>
          <Button
            type="default"
            style={{ marginBottom: 32, marginRight: 8 }}
            onClick={() => onCancel(unsavedChanges)}
          >
            {t("drawerButtons.cancel")}
          </Button>
          <Button
            type="primary"
            style={{ marginBottom: 32 }}
            htmlType="submit"
            disabled={!unsavedChanges}
          >
            {t("drawerButtons.save")}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default KioskAndValidatorUserForm;
