import { Autocomplete, GoogleMap, Marker } from "@react-google-maps/api";
import { Button, Input } from "antd";
import React, { useState } from "react";

const mapStyle = {
  height: "300px",
  width: "100 vw",
  textAlign: "center",
};

const MapWithAutocomplete = ({
  setSearchInput,
  setSelectedLocation,
  setUnsavedChanges,
  autocomplete,
  selectedLocation,
  setAutocomplete,
  searchInput,
}) => {
  const handleInputClear = () => {
    setSearchInput("");
    setSelectedLocation(null);
    setUnsavedChanges(true);
  };
  const [center] = useState({
    lat: 35.90066951,
    lng: 14.42897017,
  });
  const handlePlaceSelect = () => {
    setSearchInput(autocomplete.getPlace().formatted_address);
    const place = autocomplete.getPlace();
    if (place.geometry) {
      setSelectedLocation({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      setUnsavedChanges(true);
    }
  };
  const handleMapClick = (e) => {
    setSelectedLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
    setUnsavedChanges(true);
  };

  return (
    <GoogleMap
      mapContainerStyle={mapStyle}
      zoom={10}
      center={selectedLocation || { lat: center.lat, lng: center.lng }}
      options={{
        mapTypeControl: true, // Hide the satellite/map buttons
        fullscreenControl: false, // Hide the toggle full screen button
        streetViewControl: true, // Hide the drag pegman feature
        mapTypeControlOptions: {
          position: window.google.maps.ControlPosition.BOTTOM_LEFT, // Set the position to the bottom
          style: window.google.maps.MapTypeControlStyle.DEFAULT, // Customize the style of map type buttons
        },
        // mapTypeId: 'satellite', // Set the default view to satellite
      }}
      onClick={handleMapClick}
    >
      {selectedLocation && <Marker position={selectedLocation} />}

      <Autocomplete
        onLoad={(auto) => setAutocomplete(auto)}
        onPlaceChanged={handlePlaceSelect}
      >
        <div className="autocomplete-input">
          <Input
            placeholder="Search for a location"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            style={{
              width: "90%",
              position: "relative",
              margin: "10px 10px 0 10px",
            }}
          />
          {searchInput && (
            <Button
              type="link"
              onClick={handleInputClear}
              style={{
                position: "absolute",
                right: 0,
                margin: "10px 30px 0 10px",
                // top: '50%',
                // transform: 'translateY(-50%)',
              }}
            >
              Clear
            </Button>
          )}
        </div>
      </Autocomplete>
    </GoogleMap>
  );
};

export default MapWithAutocomplete;
