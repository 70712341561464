import React from "react";
import { Modal } from "antd";
import CustomTableComponents from "../CommonComponents/CustomTableComponents";
import { style } from "../../Styles";
import { formatDateAndTime } from "../../Utils/Imports/date-utils";
import { MinusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const TicketCardModal = ({
  isModalOpen,
  handleCancel,
  toBeDisplayed,
  data,
}) => {
  const { t } = useTranslation();
  const ticketColumns = [
    {
      title: t("ticketScreen.table.ticketNumber"),
      key: "id",
      render: (record) => {
        return <span>{record?.id}</span>;
      },
      sorter: (a, b) => a.id - b.id,
      show: true,
    },
    {
      title: t("ticketScreen.table.referenceNumber"),
      key: "ticketReferenceNumber",
      render: (record) => {
        return <span>{record.ticketReferenceNumber}</span>;
      },
      sorter: (a, b) => a.ticketReferenceNumber - b.ticketReferenceNumber,
      show: true,
    },
    {
      title: t("ticketScreen.table.printedDate"),
      key: "printedDate",
      render: (record) => {
        return record?.created ? (
          formatDateAndTime(record?.created)
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => {
        const dateA = new Date(a.created);
        const dateB = new Date(b.created);
        return dateA.getTime() - dateB.getTime();
      },
      show: true,
    },
    {
      title: t("ticketScreen.table.bundle"),
      key: "bundle",
      render: (record) => {
        return <span>{record.bundle?.name}</span>;
      },
      sorter: (a, b) => a.bundle?.name.length - b.bundle?.name.length,
      show: true,
    },
    {
      title: t("ticketScreen.table.numberOfScans"),
      key: "numberOfScan",
      render: (record) => {
        return <span style={style.dataContainer}>{record.numberOfScan}</span>;
      },
      sorter: (a, b) => a.numberOfScan - b.numberOfScan,
      show: true,
    },
  ];

  const cardColumns = [
    {
      title: "Card Number",
      key: "cardNumber",
      render: (record) => {
        return <span>{record.cardNumber}</span>;
      },
      //sorter: (a, b) => a.name.length - b.name.length,
      show: true,
    },
    {
      title: "Number of Trips",
      key: "scanNumber",
      render: (record) => {
        return (
          <span>{`${record.usedTrips} / ${record.totalNumberOfTrips}`}</span>
        );
      },
    },
    {
      title: "Status",
      key: "active",
      render: (record) => {
        return (
          <span style={style.dataContainer}>
            {record.active ? (
              <div style={style.statusActif}>ACTIVE</div>
            ) : (
              <div style={style.statusInactif}>INACTIVE</div>
            )}
          </span>
        );
      },
      // sorter: (a, b) => a.name.length - b.name.length,
      show: true,
    },
  ];

  return (
    <Modal
      title={toBeDisplayed === "ticket" ? "Tickets" : "Cards"}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      width={950}
    >
      <CustomTableComponents
        columns={toBeDisplayed === "ticket" ? ticketColumns : cardColumns}
        dataArray={toBeDisplayed === "ticket" ? data.tickets : data.cards}
        handleDoubleClickRow={() => {}}
        handleSelectedRow={() => {}}
        isMakingApiCall={false}
      />
    </Modal>
  );
};

export default TicketCardModal;
