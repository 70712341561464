export const enumToUserFriendlyText = (enumValue) => {
  if (!enumValue) {
    return "-";
  }

  return enumValue
    .split("_") // Split the string by underscores
    .map(
      (
        word // Map over the array of words
      ) => word.charAt(0) + word.slice(1).toLowerCase() // Capitalize the first letter of each word and make the rest lowercase
    )
    .join(" "); // Join the array of words with a space
};

export const userFriendlyTextToEnum = (enumValue, valueToAdd = "") => {
  if (!enumValue) {
    return null;
  }
  
  const val =  enumValue
    .split(" ") // Split the string by spaces
    .map(
      (word) => word.toUpperCase() // Convert each word to uppercase
    )
    .join("_") + (valueToAdd ? `_${valueToAdd}` : "").replace(" ", "_"); // Join the array with underscores and add the value passed in parameter
    return val;
};
