let DEFAULT = "localhost";
if (process.env.NODE_ENV === "production") {
  DEFAULT = "qa";
}

const env = {
  production: {
    baseUri: process.env.REACT_APP_API_URL_PROD,
  },
  qa: {
    baseUri: process.env.REACT_APP_API_URL_QA,
  },
  localhost: {
    baseUri: process.env.REACT_APP_API_URL_LOCAL,
  },
};

export default env[DEFAULT];
