import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { clearProfile } from "./userSlice";

const initialState = {
  auth: { loading: false, loggedIn: [], token: null, init: false },
};

export const auth = createAsyncThunk("Auth/login", async (values) => {
  try {
    const response = await axios.post(`/login`, values);
    return response.data;
  } catch (error) {
    const customError = {
      name: "Error login",
      message: error.response.data.message,
    };
    throw customError;
  }
});

export const logout = createAsyncThunk("Auth/logout", async () => {
  const response = await axios.post("/logout", {
    withCredentials: true,
  });
  return response.data;
});

export const forgetPasswordRequest = createAsyncThunk(
  "Auth/forgetPasswordRequest",
  async (email) => {
    try {
      const response = await axios.post(`/requestPasswordReset`, { email });
      return response.data;
    } catch (error) {
      const customError = {
        name: "Error forget password",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const checkPasswordResetCode = createAsyncThunk(
  "Auth/checkPasswordResetCode",
  async (values) => {
    try {
      const response = await axios.post(`/checkPasswordResetCode`, values);
      return response.data;
    } catch (error) {
      const customError = {
        name: "Error check password reset code",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const changePassword = createAsyncThunk(
  "Auth/changePassword",
  async (values) => {
    try {
      const response = await axios.post(`/resetPassword`, values);
      return response.data;
    } catch (error) {
      const customError = {
        name: "Error change password",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);


export const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.auth.token = action.payload;
      state.auth.loading = false;
    },
    setLoggedIn: (state, action) => {
      state.auth.loggedIn = action.payload;
      state.auth.loading = false;
    },
  },
  extraReducers: {
    [auth.pending]: (state) => {
      state.auth.loading = true;
    },
    [auth.rejected]: (state) => {
      state.auth.loading = false;
    },
    [auth.fulfilled]: (state, action) => {
      state.auth.loading = false;
      state.auth.loggedIn = action.payload;
      state.auth.token = action.payload.accessToken;
      state.auth.init = true;
    },
    [logout.fulfilled]: (state) => {
      state.auth.loggedIn = [];
      state.auth.init = false;
      state.auth.token = null;
      clearProfile();
    },
  },
});
export const { setToken, setLoggedIn } = authSlice.actions;
export default authSlice.reducer;
