import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from '../axios';

const initialState = {
    cardTypes : [],
    loading: false,
    selectedCardTypes: null
 };
 
 export const getCardTypes = createAsyncThunk('CardType/getCardTypes', async () => {
     try {
         const response = await axios.get(`card-type`);
         return response.data;
     } catch (error) {
         const customError = {
             name: error.code,
             message: error.message,
         };
         throw customError;
     }
 });
 
 export const getCardTypeById = createAsyncThunk('CardType/getCardTypeById', async (id) => {
     try {
         const response = await axios.get(`/card-type/${id}`);
         return response.data;
     } catch (error) {
         const customError = {
             name: error.code,
             message: error.message,
         };
         throw customError;
     }
 });
 
 export const saveCardType = createAsyncThunk('CardType/saveCardType', async (cardType) => {
     try {
         const response = await axios.post(`/card-type`, cardType);
         return response.data;
     } catch (error) {
         const customError = {
             name: error.code,
             message: error.message,
         };
         throw customError;
     }
 });
 
 export const updateCardType = createAsyncThunk('CardType/updateCardType', async (data) => {
     try {
         const response = await axios.put(`/card-type/${data.id}`, data.cardType);
         return response.data;
     } catch (error) {
         const customError = {
             name: error.code,
             message: error.message,
         };
         throw customError;
     }
 });

 export const changeCardTypeStatus = createAsyncThunk('CardType/changeCardTypeStatus', async (data) => {
  try {
      const response = await axios.put(`/card-type/${data.id}/active?active=${data.active}`);  
      return response.data;
  } catch (error) {
      const customError = {
          name: error.code,
          message: error.message,
      };
      throw customError;
  }
});
 
 export const deleteCardType = createAsyncThunk('CardType/deleteCardType', async (id) => {
     try {
         await axios.delete(`/card-type/${id}`);
         return id;
     } catch (error) {
         const customError = {
             name: error.code,
             message: error.message,
         };
         throw customError;
     }
 });


 export const cardTypeSlice = createSlice({
    name: "CardType",
    initialState,
    reducers: {
    },
    extraReducers: {
      [getCardTypes.pending]: (state) => {
        state.loading = true;
      },
      [getCardTypes.rejected]: (state) => {
        state.loading = false;
      },
      [getCardTypes.fulfilled]: (state, action) => {
        state.loading = false;
        state.cardTypes = Object.values(action.payload);
      },
      [saveCardType.pending]: (state) => {
        state.loading = true;
      },
      [saveCardType.rejected]: (state) => {
        state.cardTypes.loading = false;
      },
      [saveCardType.fulfilled]: (state, action) => {
        state.loading = false;
        state.cardTypes.push(action.payload);
      },
      [getCardTypeById.pending]: (state) => {
        state.loading = true;
      },
      [getCardTypeById.rejected]: (state) => {
        state.loading = false;
      },
      [getCardTypeById.fulfilled]: (state, action) => {
        state.loading = false;
        state.selectedCardTypes = action.payload;
      },
      [deleteCardType.pending]: (state) => {
        state.loading = true;
      },
      [deleteCardType.rejected]: (state) => {
        state.loading = false;
      },
      [deleteCardType.fulfilled]: (state, action) => {
        state.loading = false;
        state.cardTypes = state.cardTypes.filter((item) => {
          return item.id !== action.payload;
        });
      },
      [updateCardType.pending]: (state) => {
        state.loading = true;
      },
      [updateCardType.rejected]: (state) => {
        state.loading = false;
      },
      [updateCardType.fulfilled]: (state, action) => {
        state.loading = false;
        state.cardTypes = state.cardTypes.map((item) => {
          if (item.id === action.payload.id) {
            item = action.payload;
            return item;
          }
          return item;
        });
      },
      [changeCardTypeStatus.pending]: (state) => {
        state.loading = true;
      },
      [changeCardTypeStatus.rejected]: (state) => {
        state.loading = false;
      },
      [changeCardTypeStatus.fulfilled]: (state, action) => {
        state.loading = false;
        state.cardTypes = state.cardTypes.map((item) => {
          if (item.id === action.payload.id) {
            item = action.payload;
            return item;
          }
          return item;
        });
      },
    },
});

export default cardTypeSlice.reducer
