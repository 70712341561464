import { Empty, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const CustomTableComponents = ({
  isMakingApiCall,
  columns,
  dataArray,
  handleSelectedRow,
  handleDoubleClickRow,
  numberOfRows,
}) => {
  const pageSize = numberOfRows || 10;
  const showSizeChanger = !numberOfRows;
  const { t } = useTranslation();
  return (
    <div style={{ overflowX: "auto" }}>
      <Table
        pagination={{
          defaultPageSize: pageSize || 10,
          showSizeChanger,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          pageSizeOptions: ["10", "20", "50", "100"],
        }}
        loading={{
          spinning: isMakingApiCall || false,
          indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
          tip: (
            <div>
              <h2>{t("loading")}</h2>
            </div>
          ),
        }}
        columns={columns}
        dataSource={dataArray}
        onRow={(record) => {
          return {
            onClick: () => handleSelectedRow(record),
            onDoubleClick: () => handleDoubleClickRow(record),
          };
        }}
        scroll={{ x: "max-content", y: "73vh" }}
        //change the text of the table No Data
        locale={{
          emptyText: <Empty description={t("noData")} />,
        }}
      />
    </div>
  );
};

export default CustomTableComponents;
