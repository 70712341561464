import React, { useState } from "react";
import { Button, Checkbox, Form, Input, message, Col, Row } from "antd";

import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { auth } from "../../redux/authSlice";
import kioskLogin from "../../icons/kiosklogin.png";

const Login = () => {
  const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [makingApiCall, setMakingApiCall] = useState(false);

  const deleteAllCookies = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
  };

  const onFinish = (values) => {
    setMakingApiCall(true);
    // ? before sending the request to the server, we need to clear the cookies
    deleteAllCookies();

    dispatch(auth(values))
      .unwrap()
      .then(() => {
        navigate("/app/main/kiosks");
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
        switch (e.message) {
          case "USER_NOT_ACTIVE":
            message.error("Your account is not active. Please contact admin");
            break;
          case "USER_DO_NOT_HAVE_PERMISSION_TO_ACCESS_PORTAL":
            message.error("Invalid credentials");
            break;
          default:
            message.error("Invalid credentials");
            break;
        }
      })
      .finally(() => {
        setMakingApiCall(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: "100vh" }}
      >
        <Col
          xs={{ span: 0 }}
          sm={{ span: 0 }}
          md={{ span: 0 }}
          lg={{ span: 12 }}
        >
          <img
            style={{
              width: "70%",
              height: "70%",
              objectFit: "contain",
            }}
            src={kioskLogin}
            alt="logo"
          />
        </Col>
        <Col xs={{ span: 12, offset: 1 }} lg={{ span: 6, offset: 1 }}>
          <Form
            name="login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <h1 style={{ textAlign: "center" }}>Login</h1>

            <Form.Item
              label="Username"
              name="username"
              rules={[
                { required: true, message: "Please input your username" },
              ]}
            >
              <Input size="large" style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password" },
              ]}
            >
              <Input.Password size="large" style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Checkbox
                  style={{
                    alignSelf: "center",
                    color: primaryColor,
                    fontWeight: "bold",
                  }}
                >
                  Remember me
                </Checkbox>
                <Link
                  to="/app/forget-password"
                  style={{
                    alignSelf: "center",
                    color: primaryColor,
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  Forgot Password?
                </Link>
              </div>
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit" disabled={makingApiCall}>
                {makingApiCall ? "logging in..." : "Login"}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Login;
