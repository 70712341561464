import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axios';

const initialState = {
	files: {
		loading: false,
		files: [],
	},
};

export const uploadProfilPicture = createAsyncThunk('Files/uploadProfilePicture', async (file) => {
	try {
		const formData = new FormData();
		formData.append('file', file);
		const response = await axios.post(`files/profilePicture`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return response.data;
	} catch (error) {
		const customError = {
			name: error.name,
			message: error.response.data.message,
		};
		throw customError;
	}
});


export const fileSlice = createSlice({
	name: 'Files',
	initialState,
	reducers: {},
	extraReducers: {
	},
});

export default fileSlice.reducer;
