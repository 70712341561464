import { Avatar } from "antd";
import React from "react";

const CustomAvatar = ({ type, name, size }) => {
  const avatarUrl = process.env.REACT_APP_AVATAR_URL;
  const avatarTypes = [
    "bottts",
    "adventurer",
    "adventurer-neutral",
    "avataaars",
    "avataaars-neutral",
    "big-ears",
    "big-ears-neutral",
    "big-smile",
    "initials",
    "lorelei",
    "lorelei-neutral",
    "micah",
    "miniavs",
    "open-peeps",
    "personas",
    "pixel-art",
    "pixel-art-neutral",
    "shapes",
    "thumbs",
  ];
  // selected type find in the array of types the type that matches the type passed in the props or return the first type in the array
  const selectedType = avatarTypes.find((t) => t === type) || "bottts-neutral";
  return (
    <Avatar
      gap={4}
      shape="circle"
      className="custom-avatar"
      size={size || 35}
      src={`${avatarUrl}/${selectedType}/svg?seed=${name}`}
      style={{ marginRight: "0.5em" }}
    />
  );
};

export default CustomAvatar;
