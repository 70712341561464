import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Define the async thunk for fetching the data
export const fetchData = createAsyncThunk("statistics/fetchData", async () => {
    try {
        // var resp = await axios.get("http://localhost/api/statistics").then((response) => {
        //     return response.data;
        // });
        var resp = [
            {
                "id": 1,
                "boughtTickets": 100,
                "boughtCards": 200,
                "toppedUpCards": 300,
                "created": "2024-02-14T12:34:56.789Z",
                "updated": "2024-02-14T12:34:56.789Z",
                "deleted": null
            }
        ];
        return resp;
    } catch (error) {
        throw error;
    }
});

export const statisticsSlice = createSlice({
    name: "Statistics",
    initialState: {
        statistics: { loading: false, dataList: [] }
    },
    reducers: {},
    extraReducers: {
        [fetchData.pending]: (state) => {
            state.statistics.loading = true;
        },
        [fetchData.rejected]: (state) => {
            state.status = "failed";
        },
        [fetchData.fulfilled]: (state, action) => {
            state.statistics.dataList = action.payload;
            state.statistics.loading = false;
        }
    },
});

export default statisticsSlice.reducer;
