import React from "react";
import { Form, Input, Select, Button, Divider, Spin, Tag, Radio } from "antd";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { style } from "../../Styles";
import { bundleType } from "../../Utils/Constants/utils";
import { useTranslation } from "react-i18next";

const CardTypeForm = ({
  onSave,
  onSaveFailed,
  cardType,
  onCancel,
  onDirty,
  unsavedChanges,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const initialValues = {
    ...cardType,
  };

  return (
    <Form
      form={form}
      name="cardType"
      layout="vertical"
      initialValues={initialValues}
      onFinish={onSave}
      onFinishFailed={onSaveFailed}
      autoComplete="off"
      onValuesChange={() => onDirty()}
    >
      <Form.Item
        label={t("cardTypeScreen.form.name")}
        name="name"
        rules={[{ required: true, message: t("cardTypeScreen.form.inputName") }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("cardTypeScreen.form.description")}
        name="description"
        rules={[{ required: false }]}
      >
        <Input />
      </Form.Item>
      <Divider />
      <Form.Item style={{ float: "right" }}>
        <Button
          type="default"
          style={{ marginBottom: 32, marginRight: 8 }}
          onClick={() => onCancel(unsavedChanges)}
        >
          {t("cardTypeScreen.form.cancel")}
        </Button>
        <Button
          type="primary"
          style={{ marginBottom: 32 }}
          htmlType="submit"
          disabled={!unsavedChanges}
        >
          {cardType ? t("cardTypeScreen.form.update") : t("cardTypeScreen.form.save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CardTypeForm;
