import React, { useState, useEffect, useCallback } from "react";
import { Drawer, message, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ShowConfirmClose from "../Modals/ShowConfirmClose";
import {
  getBundleById,
  saveBundle,
  updateBundle,
} from "../../redux/bundleSlice";
import BundleForm from "./BundleForm";
import { tenantId } from "../../Utils/Constants/utils";
import { useTranslation } from "react-i18next";

const DrawerUser = ({ onClose, bundleId }) => {
  const { t } = useTranslation();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const dispatch = useDispatch();
  const [makingApiCall, setMakingApiCall] = useState(false);
  const [bundle, setBundle] = useState(undefined);
  const { cardTypes } = useSelector((state) => state.cardTypes);
  useEffect(() => {
    const abortController = new AbortController();
    if (bundleId) {
      setMakingApiCall(true);
      dispatch(getBundleById(bundleId))
        .unwrap()
        .then((data) => {
          setBundle(data);
          setMakingApiCall(false);
        })
        .catch(() => {
          setMakingApiCall(false);
        });
    }
    return () => abortController.abort();
  }, [bundleId, dispatch]);

  const handleDirty = useCallback(() => {
    setUnsavedChanges(true);
  }, []);

  const onSave = async (values) => {
    const action = bundleId
      ? updateBundle({
          id: bundleId,
          bundle: {
            ...values,
            active: bundle.active,
            tenantId,
          },
        })
      : saveBundle({
          ...values,
          tenantId,
        });

    setMakingApiCall(true);
    dispatch(action)
      .then(() => {
        message.success(t("bundleScreen.messages.bundleSavedSuccessfully"));
        onClose();
        setUnsavedChanges(false);
      })
      .catch((e) => {
        message.error(e.message);
      })
      .finally(() => {
        setMakingApiCall(false);
      });
  };

  const onSaveFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleClose = useCallback(
    (hasUnsavedChanges) => {
      if (hasUnsavedChanges && !confirmationOpen) {
        setConfirmationOpen(true); // Mark that the confirmation modal is open
        ShowConfirmClose(() => {
          setConfirmationOpen(false); // Reset when the confirmation modal is closed
          onClose();
        });
      } else {
        onClose();
      }
    },
    [onClose, confirmationOpen]
  );
  return (
    <Drawer
      title={
        !bundleId
          ? t("bundleScreen.form.addBundle")
          : t("bundleScreen.form.editBundle")
      }
      placement="right"
      onClose={() => handleClose(unsavedChanges)}
      width={500}
      open
    >
      <Spin
        size="large"
        spinning={makingApiCall}
        style={{ marginTop: 64 }}
        delay={500}
      >
        {!makingApiCall && (
          <BundleForm
            bundle={bundle}
            cardTypes={cardTypes}
            onSave={onSave}
            onSaveFailed={onSaveFailed}
            onCancel={onClose}
            onDirty={handleDirty}
            unsavedChanges={unsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        )}
      </Spin>
    </Drawer>
  );
};

export default DrawerUser;
