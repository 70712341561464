/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button, Form, Input, message, Col, Row } from "antd";

import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { changePassword } from "../../redux/authSlice";
import ResetPasswordSvg from "../../icons/svgImages/ResetPasswordSvg";

const ChangePassword = () => {
  const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;
  const [makingApiCall, setMakingApiCall] = useState(false);
  const location = useLocation();
  const email = location?.state?.email;
  const code = location?.state?.code;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onFinish = (values) => {
    setMakingApiCall(true);
    dispatch(
      changePassword({
        email: email.trim().toLowerCase(),
        code,
        password: values.newPassword,
      })
    )
      .unwrap()
      .then(() => {
        message.success("Password changed successfully");
        navigate("/app/login");
      })
      .catch((e) => {
        if (e.message === "INVALID_CODE") {
          message.error("The code you entered is invalid");
        } else if (e.message === "CODE_EXPIRED") {
          message.error("The code you entered has expired");
        } else {
          message.error(
            "Error while reseting your password, please try again later"
          );
        }
      })
      .finally(() => {
        setMakingApiCall(false);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (!email || !code) {
      navigate("/app/login");
    }
  }, [email, code]);

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: "100vh" }}
    >
      <Col xs={{ span: 12, offset: 1 }} lg={{ span: 10, offset: 1 }}>
        <ResetPasswordSvg fillColor={primaryColor || "#ffa500"} />
      </Col>
      <Col xs={24} md={12} lg={12}>
        <Form
          name="login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <h1 style={{ textAlign: "center" }}>Change password</h1>
          <p style={{ color: "#6c757d" }}>
            Congratulations! You're almost done. Please enter your new password
            and confirm it below to complete the password reset process.
          </p>
          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[{ required: true, message: "New password is required" }]}
          >
            <Input.Password style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={["newPassword"]}
            rules={[
              {
                required: true,
                message: "Please confirm your new password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords that you entered do not match")
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit" disabled={makingApiCall}>
              {makingApiCall ? "Changing password..." : "Change password"}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default ChangePassword;
