import React, { useState, useEffect, useCallback } from "react";
import { Drawer, message, Spin } from "antd";
import { useDispatch } from "react-redux";
import ShowConfirmClose from "../Modals/ShowConfirmClose";

import { getCardTypeById, saveCardType, updateCardType } from "../../redux/cardTypeSlice";
import CardTypeForm from "./CardTypeForm";
import { useTranslation } from "react-i18next";

const CardTypeDrawer = ({ onClose, cardTypeId }) => {
  const { t } = useTranslation();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const dispatch = useDispatch();
  const [makingApiCall, setMakingApiCall] = useState(false);
  const [cardType, setCardType] = useState(undefined);

  useEffect(() => {
    const abortController = new AbortController();
    if (cardTypeId) {
      setMakingApiCall(true);
      dispatch(getCardTypeById(cardTypeId))
        .unwrap()
        .then((data) => {
          setCardType(data);
          setMakingApiCall(false);
        })
        .catch((err) => {
            console.error("err", err)
          setMakingApiCall(false);
        });
    }
    return () => abortController.abort();
  }, [cardTypeId, dispatch]);

  const handleDirty = useCallback(() => {
    setUnsavedChanges(true);
  }, []);

  const onSave = async (values) => {
    const action = cardTypeId
      ? updateCardType({
          id: cardTypeId,
          cardType: {
			...values,
			active: cardType.active,
		  },
        })
      : saveCardType({
		  ...values,
	  });

    setMakingApiCall(true);
	dispatch(action)
	.then(() => {
		message.success(t("cardTypeScreen.messages.CardTypeSavedSuccessfully"));
		onClose();
		setUnsavedChanges(false);
	})
	.catch((e) => {
		message.error(e.message);
	})
	.finally(() => {
		setMakingApiCall(false);
	});
  };

  const onSaveFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleClose = useCallback(
    (hasUnsavedChanges) => {
      if (hasUnsavedChanges && !confirmationOpen) {
        setConfirmationOpen(true); // Mark that the confirmation modal is open
        ShowConfirmClose(() => {
          setConfirmationOpen(false); // Reset when the confirmation modal is closed
          onClose();
        });
      } else {
        onClose();
      }
    },
    [onClose, confirmationOpen]
  );
  return (
    <Drawer
      title={!cardType ? t("cardTypeScreen.form.addCardType") : t("cardTypeScreen.form.updateCardType")}
      placement="right"
      onClose={() => handleClose(unsavedChanges)}
      width={500}
      open
    >
      <Spin
        size="large"
        spinning={makingApiCall}
        style={{ marginTop: 64 }}
        delay={500}
      >
        {!makingApiCall && (
          <CardTypeForm
            cardType={cardType}
            onSave={onSave}
            onSaveFailed={onSaveFailed}
            onCancel={onClose}
            onDirty={handleDirty}
            unsavedChanges={unsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        )}
      </Spin>
    </Drawer>
  );
};

export default CardTypeDrawer;
