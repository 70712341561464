import React, { useCallback, useEffect, useState } from "react";
import { Drawer, message, Spin } from "antd";
import { useDispatch } from "react-redux";
import ShowConfirmClose from "../Modals/ShowConfirmClose";
import HardwareForm from "./HardwareForm";
import {
  getHardwareById,
  saveHardware,
  updateHardware,
} from "../../redux/hardwareSlice";
import { getAllKiosks } from "../../redux/kiosksSlice";
import { useTranslation } from "react-i18next";

const HardwareDrawer = ({ hardwareId, onClose }) => {
  const { t } = useTranslation();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const dispatch = useDispatch();
  const [makingApiCall, setMakingApiCall] = useState(false);
  const [hardware, setHardware] = useState(undefined);

  useEffect(() => {
    const abortController = new AbortController();
    if (hardwareId) {
      setMakingApiCall(true);
      dispatch(getHardwareById(hardwareId))
        .unwrap()
        .then((data) => {
          setHardware(data);
          setMakingApiCall(false);
        })
        .catch(() => {
          setMakingApiCall(false);
        });
    }
    return () => abortController.abort();
  }, [hardwareId, dispatch]);

  useEffect(() => {
    dispatch(getAllKiosks());
  }, [dispatch]);

  const handleDirty = useCallback(() => {
    setUnsavedChanges(true);
  }, []);

  const onSave = async (values) => {
    const action = hardwareId
      ? updateHardware({
          id: hardwareId,
          hardware: {
            ...values,
            active: hardware.active,
          },
        })
      : saveHardware({
          ...values,
        });
    setMakingApiCall(true);

    dispatch(action)
      .then(() => {
        message.success(t("hardwareScreen.messages.hardwareSavedSuccessfully"));
        onClose();
        setUnsavedChanges(false);
      })
      .catch((e) => {
        message.error(e.message);
      })
      .finally(() => {
        setMakingApiCall(false);
      });
  };

  const onSaveFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleClose = useCallback(
    (hasUnsavedChanges) => {
      if (hasUnsavedChanges && !confirmationOpen) {
        setConfirmationOpen(true); // Mark that the confirmation modal is open
        ShowConfirmClose(() => {
          setConfirmationOpen(false); // Reset when the confirmation modal is closed
          onClose();
        });
      } else {
        onClose();
      }
    },
    [onClose, confirmationOpen]
  );

  return (
    <Drawer
      title={
        !hardwareId
          ? t("hardwareScreen.form.addHardware")
          : t("hardwareScreen.form.editHardware")
      }
      placement="right"
      onClose={() => handleClose(unsavedChanges)}
      width={500}
      open
    >
      <Spin
        size="large"
        spinning={makingApiCall}
        style={{ marginTop: 64 }}
        delay={500}
      >
        {!makingApiCall && (
          <HardwareForm
            hardware={hardware}
            onSave={onSave}
            onSaveFailed={onSaveFailed}
            onCancel={onClose}
            onDirty={handleDirty}
            unsavedChanges={unsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        )}
      </Spin>
    </Drawer>
  );
};

export default HardwareDrawer;
