import { Button, Dropdown, Menu, Modal } from "antd";
import React, { useState } from "react";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  StopOutlined,
  CheckOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ActionButtonWithChangeStatus = ({
  record,
  onDelete,
  onRowClick,
  onChangeStatus,
  recordName
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const { t } = useTranslation();
  const { auth } = useSelector((auth) => auth.auth )
  const handleMenuClick = ({ key }) => {
    // switch case depending on the key
    switch (key) {
      case "edit":
        onRowClick(record);
        break;
      case "delete":
        Modal.confirm({
          title: (
            <>
              Are you sure you want to delete <b>{recordName || record.name}</b>{" "}
              ?
            </>
          ),
          icon: <ExclamationCircleFilled style={{ color: "#4d609b" }} />,
          okText: "Yes", // Customize OK button text
          cancelText: "No", // Customize Cancel button text
          cancelButtonProps: {
            style: {
              color: "#4d609b",
              backgroundColor: "white",
              borderColor: "#4d609b",
            },
          },
          okButtonProps: {
            style: {
              color: "white",
              backgroundColor: "#4d609b",
            },
          },
          onOk: () => {
            onDelete(record.id, () => {});
          },
          onCancel: () => {},
        });
        break;
      case "disable":
        Modal.confirm({
          title: (
            <>
              Are you sure you want to disable{" "}
              <b>{recordName || record.name}</b> ?
            </>
          ),
          icon: <ExclamationCircleFilled style={{ color: "#4d609b" }} />,
          okText: "Yes", // Customize OK button text
          cancelText: "No", // Customize Cancel button text
          cancelButtonProps: {
            style: {
              color: "#4d609b",
              backgroundColor: "white",
              borderColor: "#4d609b",
            },
          },
          okButtonProps: {
            style: {
              color: "white",
              backgroundColor: "#4d609b",
            },
          },
          onOk: () => {
            const ObjToSend = {
              ...record,
              active: false,
            };
            onChangeStatus(ObjToSend);
          },
          onCancel: () => {},
        });
        break;

      case "enable":
        Modal.confirm({
          title: (
            <>
              Are you sure you want to enable <b>{recordName || record.name}</b>
              ?
            </>
          ),
          icon: <ExclamationCircleFilled style={{ color: "#4d609b" }} />,
          okText: "Yes", // Customize OK button text
          cancelText: "No", // Customize Cancel button text
          cancelButtonProps: {
            style: {
              color: "#4d609b",
              backgroundColor: "white",
              borderColor: "#4d609b",
            },
          },
          okButtonProps: {
            style: {
              color: "white",
              backgroundColor: "#4d609b",
            },
          },
          onOk: () => {
            const ObjToSend = {
              ...record,
              active: true,
            };
            onChangeStatus(ObjToSend);
          },
          onCancel: () => {},
        });
        break;
      default:
        break;
    }
    setMenuVisible(false);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="edit" icon={<EditOutlined />}>
        {t("actions.edit")}
      </Menu.Item>
      {record.active ? (
        <Menu.Item key="disable" icon={<StopOutlined />} danger>
          {t("actions.disable")}
        </Menu.Item>
      ) : (
        <Menu.Item key="enable" icon={<CheckOutlined />}>
          {t("actions.enable")}
        </Menu.Item>
      )}
      {auth?.loggedIn?.username != record?.username && <Menu.Item key="delete" icon={<DeleteOutlined />} danger>
        {t("actions.delete")}
      </Menu.Item>}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      visible={menuVisible}
      onVisibleChange={(visible) => {
        setMenuVisible(visible);
      }}
    >
      <Button
        type="text"
        icon={<MoreOutlined />}
        onClick={(event) => event.stopPropagation()}
      />
    </Dropdown>
  );
};

export default ActionButtonWithChangeStatus;
