import { Modal } from "antd";

export default (onOk) => {
  Modal.confirm({
    title: "Are you sure you want to continue?",
    content: "All unsaved changes will be lost.",
    autoFocusButton: null,
    okText: "Continue",
    cancelText: "Cancel",
    cancelButtonProps: {
      style: {
        color: "#4d609b",
        backgroundColor: "white",
        borderColor: "#4d609b",
      },
    },
    okButtonProps: {
      style: { color: "white", backgroundColor: "#4d609b" },
    },
    onOk() {
      onOk();
    },
    onCancel() {},
  });
};
