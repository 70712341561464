import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";

// Define the async thunk for fetching the data
export const getAllHardwareLogs = createAsyncThunk(
  "hardwareLog/getAllLogs",
  async (payload) => {
    try {
      const response = await axios.patch("/hardware-logs", payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getHardwareLogsByDateRangeAndDevice = createAsyncThunk(
  "hardwareLog/getHardwareLogsByDateRangeAndDevice",
  async (payload) => {
    try {
      const response = await axios.patch("/hardware-logs/filter", payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const hardwareLogSlice = createSlice({
  name: "hardwareLog",
  initialState: {
    hardwareLogs: { loading: false, data: [] },
  },
  reducers: {},
  extraReducers: {
    [getAllHardwareLogs.pending]: (state) => {
      state.hardwareLogs.loading = true;
    },
    [getAllHardwareLogs.rejected]: (state, action) => {
      state.hardwareLogs.loading = false;
    },
    [getAllHardwareLogs.fulfilled]: (state, action) => {
      state.hardwareLogs.data = action.payload;
      state.hardwareLogs.loading = false;
    },
    [getHardwareLogsByDateRangeAndDevice.pending]: (state) => {
      state.hardwareLogs.loading = true;
    },
    [getHardwareLogsByDateRangeAndDevice.rejected]: (state, action) => {
      state.hardwareLogs.loading = false;
    },
    [getHardwareLogsByDateRangeAndDevice.fulfilled]: (state, action) => {
      state.hardwareLogs.data = action.payload;
      state.hardwareLogs.loading = false;
    },
  },
});

export default hardwareLogSlice.reducer;
