import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from '../axios';

const initialState = {
   bundles : [],
   selectedBundle: null
};

export const getBundles = createAsyncThunk('Bundle/getBundles', async () => {
    try {
        const response = await axios.get(`bundle`);
        return response.data;
    } catch (error) {
        const customError = {
            name: error.code,
            message: error.message,
        };
        throw customError;
    }
});

export const getBundleById = createAsyncThunk('Bundle/getBundleById', async (id) => {
    try {
        const response = await axios.get(`bundle/${id}`);
        return response.data;
    } catch (error) {
        const customError = {
            name: error.code,
            message: error.message,
        };
        throw customError;
    }
});

export const saveBundle = createAsyncThunk('Bundle/saveBundle', async (bundle) => {
    try {
        const response = await axios.post(`bundle`, bundle);
        return response.data;
    } catch (error) {
        const customError = {
            name: error.code,
            message: error.message,
        };
        throw customError;
    }
});

export const updateBundle = createAsyncThunk('Bundle/updateBundle', async (data) => {
    try {
        const response = await axios.put(`bundle/${data.id}`, data.bundle);
        return response.data;
    } catch (error) {
        const customError = {
            name: error.code,
            message: error.message,
        };
        throw customError;
    }
});

export const deleteBundle = createAsyncThunk('Bundle/deleteBundle', async (id) => {
    try {
        await axios.delete(`bundle/${id}`);
        return id;
    } catch (error) {
        const customError = {
            name: error.code,
            message: error.message,
        };
        throw customError;
    }
});

export const changeBundleStatus = createAsyncThunk('Bundle/changeBundleStatus', async (data) => {
    try {
        const response = await axios.put(`bundle/${data.id}/active?active=${data.active}`);
        return response.data;
    } catch (error) {
        const customError = {
            name: error.code,
            message: error.message,
        };
        throw customError;
    }
});

export const bundleSlice = createSlice({
	name: 'Bundle',
	initialState,
	reducers: {},
	extraReducers: {
        [getBundles.fulfilled]: (state, action) => {
            state.bundles = action.payload;
        },
        [getBundleById.fulfilled]: (state, action) => {
            state.selectedBundle = action.payload;
        },
        [saveBundle.fulfilled]: (state, action) => {
            state.bundles.push(action.payload);
        },
        [updateBundle.fulfilled]: (state, action) => {
            state.bundles = state.bundles.map((item) => {
				if (item.id === action.payload.id) {
					item = action.payload;
					return item;
				}
				return item;
			});
        },
        [deleteBundle.fulfilled]: (state, action) => {
            state.bundles = state.bundles.filter((item) => item.id !== action.payload);
        },
        [changeBundleStatus.fulfilled]: (state, action) => {
            state.bundles = state.bundles.map((item) => {
                if (item.id === action.payload.id) {
                    item = action.payload;
                    return item;
                }
                return item;
            })
        },
    },
});

export default bundleSlice.reducer