import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../axios";

const initialState = {
  validators: { loading: false, data: [] },
  selectedValidator: { loading: false, data: null },
};

export const getAllValidators = createAsyncThunk(
  "validator/getAllValidators",
  async () => {
    try {
      const response = await axiosInstance.get("/validators");

      return response.data;
    } catch (error) {
      const customError = {
        name: "Validator error",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const createValidator = createAsyncThunk(
  "validator/createValidator",
  async (data) => {
    try {
      const response = await axiosInstance.post("/validator", data);
      return response.data;
    } catch (error) {
      const customError = {
        name: "Validator error",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const deleteValidator = createAsyncThunk(
  "validator/deleteValidator",
  async (id) => {
    try {
      await axiosInstance.delete(`/validator/${id}`);
      return id;
    } catch (error) {
      const customError = {
        name: "Validator error",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const updateValidator = createAsyncThunk(
  "validator/updateValidator",
  async (data) => {
    try {
      const response = await axiosInstance.put(`/validator/${data.id}`, data);
      return response.data;
    } catch (error) {
      const customError = {
        name: "Validator error",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const getValidatorById = createAsyncThunk(
  "validator/getValidator",
  async (id) => {
    try {
      const response = await axiosInstance.get(`/validator/${id}`);
      return response.data;
    } catch (error) {
      const customError = {
        name: "Validator error",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const changeValidatorStatus = createAsyncThunk(
  "validator/changeValidatorStatus",
  async (payload) => {
    try {
      const response = await axiosInstance.patch(
        `/validator/${payload?.id}/status`,
        payload?.status
      );
      return response.data;
    } catch (error) {
      const customError = {
        name: "Validator error",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

const validatorSlice = createSlice({
  name: "validator",
  initialState,
  reducers: {
    clearSelectedValidator: (state) => {
      state.selectedValidator.data = null;
    },
  },
  extraReducers: {
    [getAllValidators.pending]: (state) => {
      state.validators.loading = true;
    },
    [getAllValidators.fulfilled]: (state, action) => {
      state.validators.loading = false;
      state.validators.data = action.payload;
    },
    [getAllValidators.rejected]: (state) => {
      state.validators.loading = false;
    },

    [createValidator.pending]: (state) => {
      state.validators.loading = true;
    },
    [createValidator.fulfilled]: (state, action) => {
      state.validators.loading = false;
      state.validators.data.push(action.payload);
    },
    [createValidator.rejected]: (state) => {
      state.validators.loading = false;
    },

    [deleteValidator.pending]: (state) => {
      state.validators.loading = true;
    },
    [deleteValidator.fulfilled]: (state, action) => {
      state.validators.loading = false;
      state.validators.data = state.validators.data.filter(
        (validator) => validator.id !== action.payload
      );
    },
    [deleteValidator.rejected]: (state) => {
      state.validators.loading = false;
    },

    [updateValidator.pending]: (state) => {
      state.validators.loading = true;
    },
    [updateValidator.fulfilled]: (state, action) => {
      state.validators.loading = false;
      state.validators.data = state.validators.data.map((validator) =>
        validator.id === action.payload.id ? action.payload : validator
      );
    },
    [updateValidator.rejected]: (state) => {
      state.validators.loading = false;
    },

    [getValidatorById.pending]: (state) => {
      state.selectedValidator.loading = true;
    },
    [getValidatorById.fulfilled]: (state, action) => {
      state.selectedValidator.loading = false;
      state.selectedValidator.data = action.payload;
    },
    [getValidatorById.rejected]: (state) => {
      state.selectedValidator.loading = false;
    },

    [changeValidatorStatus.pending]: (state) => {
      state.validators.loading = true;
    },
    [changeValidatorStatus.fulfilled]: (state, action) => {
      state.validators.loading = false;
      state.validators.data = state.validators.data.map((validator) =>
        validator.id === action.payload.id ? action.payload : validator
      );
    },
    [changeValidatorStatus.rejected]: (state) => {
      state.validators.loading = false;
    },
  },
});

export const { clearSelectedValidator } = validatorSlice.actions;

export default validatorSlice.reducer;
