import React, { useEffect } from "react";
import { useForm } from "antd/es/form/Form";
import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const ValidatorForm = ({
  onSave,
  onSaveFailed,
  validator,
  onCancel,
  onDirty,
  unsavedChanges,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const initialValues = {
    ...validator,
  };

  useEffect(() => {
    if (validator) {
      form.setFieldsValue(validator);
    }
  }, [validator]);

  return (
    <>
      <Form
        form={form}
        name="Validator"
        layout="vertical"
        initialValues={initialValues}
        onFinish={onSave}
        onFinishFailed={onSaveFailed}
        autoComplete="on"
        onValuesChange={() => onDirty()}
      >
        <Form.Item
          label={t("validatorScreen.form.validatorSerialNumber")}
          name="serialNumber"
          rules={[
            {
              required: true,
              message: t(
                "validatorScreen.form.pleaseEnterValidatorSerialNumber"
              ),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item style={{ float: "right" }}>
          <Button
            type="default"
            style={{ marginBottom: 32, marginRight: 8 }}
            onClick={() => onCancel(unsavedChanges)}
          >
            {t("drawerButtons.cancel")}
          </Button>
          <Button
            type="primary"
            style={{ marginBottom: 32 }}
            htmlType="submit"
            disabled={!unsavedChanges}
          >
            {!validator ? t("drawerButtons.add") : t("drawerButtons.update")}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ValidatorForm;
