import { Button, Dropdown, Menu, Modal } from "antd";
import React, { useState } from "react";
import {
  MoreOutlined,
  StopOutlined,
  CheckOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const ActionDropdownToChangeStatus = ({
  record,
  onChangeStatus,
  recordName,
}) => {
  const { t } = useTranslation();
  const [menuVisible, setMenuVisible] = useState(false);

  const handleMenuClick = ({ key }) => {
    // switch case depending on the key
    switch (key) {
      case "disable":
        Modal.confirm({
          title: (
            <>
              {t("userScreen.actions.disable_confirm_title", {
                name: recordName || record.name,
              })}
            </>
          ),
          icon: <ExclamationCircleFilled style={{ color: "#4d609b" }} />,
          okText: t("userScreen.actions.yes"), // Customize OK button text
          cancelText: t("userScreen.actions.no"), // Customize Cancel button text
          cancelButtonProps: {
            style: {
              color: "#4d609b",
              backgroundColor: "white",
              borderColor: "#4d609b",
            },
          },
          okButtonProps: {
            style: {
              color: "white",
              backgroundColor: "#4d609b",
            },
          },
          onOk: () => {
            const ObjToSend = {
              userId: record.id,
              active: false,
            };

            onChangeStatus(ObjToSend);
          },
          onCancel: () => {},
        });
        break;

      case "enable":
        Modal.confirm({
          title: (
            <>
              {t("userScreen.actions.enable_confirm_title", {
                name: recordName || record.name,
              })}
            </>
          ),
          icon: <ExclamationCircleFilled style={{ color: "#4d609b" }} />,
          okText: t("userScreen.actions.yes"), // Customize OK button text
          cancelText: t("userScreen.actions.no"), // Customize Cancel button text
          cancelButtonProps: {
            style: {
              color: "#4d609b",
              backgroundColor: "white",
              borderColor: "#4d609b",
            },
          },
          okButtonProps: {
            style: {
              color: "white",
              backgroundColor: "#4d609b",
            },
          },
          onOk: () => {
            const ObjToSend = {
              userId: record.id,
              active: true,
            };
            onChangeStatus(ObjToSend);
          },
          onCancel: () => {},
        });
        break;
      default:
        break;
    }
    setMenuVisible(false);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {record.active ? (
        <Menu.Item key="disable" icon={<StopOutlined />} danger>
          {t("userScreen.actions.disable")}
        </Menu.Item>
      ) : (
        <Menu.Item key="enable" icon={<CheckOutlined />}>
          {t("userScreen.actions.enable")}
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      visible={menuVisible}
      onVisibleChange={(visible) => {
        setMenuVisible(visible);
      }}
    >
      <Button
        type="text"
        icon={<MoreOutlined />}
        onClick={(event) => event.stopPropagation()}
      />
    </Dropdown>
  );
};

export default ActionDropdownToChangeStatus;
