import { style } from "../../Styles";
import { getSpanStyleForHardware } from "../Style/style-depending-on-role";

export const tenantId = 1;
export const bundleType = [
  {
    value: "TICKET_TYPE",
    label: "Ticket",
  },
  {
    value: "NEW_CARD_TYPE",
    label: "New Card",
  },
  {
    value: "TOP_UP_CARD_TYPE",
    label: "Top Up Card",
  },
  {
    value: "SPECIAL_CARD_TYPE",
    label: "Special Card",
  },
];

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const hardwareName = [
  {
    value: "CARD_DISPENSER",
    label: "Card Dispenser",
    style: getSpanStyleForHardware("CARD_DISPENSER"),
  },
  {
    value: "PRINTER",
    label: "Printer",
    style: getSpanStyleForHardware("PRINTER"),
  },
  {
    value: "CASH_ACCEPTOR",
    label: "Cash Acceptor",
    style: getSpanStyleForHardware("CASH_ACCEPTOR"),
  },
  {
    value: "COIN_ACCEPTOR",
    label: "Coin Acceptor",
    style: getSpanStyleForHardware("COIN_ACCEPTOR"),
  },
  {
    value: "PAYMENT_MODULE",
    label: "Payment Module",
    style: getSpanStyleForHardware("PAYMENT_MODULE"),
  },
  {
    value: "SERVER",
    label: "Server",
    style: getSpanStyleForHardware("SERVER"),
  },
];

export const getHardwareName = (value) => {
  const hardware = hardwareName.find((hardware) => hardware.value === value);
  return hardware;
};
