import React, { useCallback, useEffect, useState } from "react";
import {
  createValidator,
  getValidatorById,
  updateValidator,
} from "../../redux/validatorSlice";
import { useSelector } from "react-redux";
import { Drawer, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ShowConfirmClose from "../Modals/ShowConfirmClose";
import ValidatorForm from "./ValidatorForm";
import { useTranslation } from "react-i18next";

const ValidatorDrawer = ({
  validatorId,
  onClose,
  dispatch,
  setValidatorId,
}) => {
  const { t } = useTranslation();
  const { selectedValidator } = useSelector((state) => state.validators);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const fetchValidatorById = () => {
    dispatch(getValidatorById(validatorId))
      .unwrap()
      .then(() => {})
      .catch(() => {});
  };

  const handleClose = useCallback(
    (hasUnsavedChanges) => {
      if (hasUnsavedChanges && !confirmationOpen) {
        setConfirmationOpen(true); // Mark that the confirmation modal is open
        ShowConfirmClose(() => {
          setConfirmationOpen(false); // Reset when the confirmation modal is closed
          onClose();
        });
      } else {
        onClose();
      }
      setValidatorId(null);
    },
    [onClose, confirmationOpen]
  );

  const handleDirty = useCallback(() => {
    setUnsavedChanges(true);
  }, []);

  const onSave = async (values) => {
    const action = validatorId
      ? updateValidator({
          id: validatorId,
          validator: {
            ...values,
            active: selectedValidator?.data?.active,
          },
        })
      : createValidator({
          ...values,
        });

    dispatch(action)
      .unwrap()
      .then(() => {
        message.success(
          t("validatorScreen.messages.validatorSavedSuccessfully")
        );
        onClose();
        setUnsavedChanges(false);
      })
      .catch((e) => {
        if (e?.message === "VALIDATOR_ALREADY_EXISTS") {
          message.error(
            t(
              "validatorScreen.messages.validatorWithSameSerialNumberAlreadyExists"
            )
          );
        } else
          message.error(
            t("validatorScreen.messages.anErrorOccurredWhileSavingValidators")
          );
      });
  };

  const onSaveFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (validatorId) fetchValidatorById();
  }, [dispatch]);
  return (
    <>
      <Drawer
        title={
          !validatorId && selectedValidator?.data === null
            ? t("validatorScreen.form.addValidator")
            : t("validatorScreen.form.editValidator")
        }
        placement="right"
        onClose={() => handleClose(unsavedChanges)}
        width={500}
        open
      >
        <Spin
          size="large"
          indicator={<LoadingOutlined style={{ fontSize: 54 }} spin />}
          tip={
            <div>
              <h2>{t("loading")}</h2>
            </div>
          }
          spinning={selectedValidator?.loading}
        >
          <ValidatorForm
            validator={selectedValidator?.data}
            onSave={onSave}
            onSaveFailed={onSaveFailed}
            onCancel={onClose}
            onDirty={handleDirty}
            unsavedChanges={unsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        </Spin>
      </Drawer>
    </>
  );
};

export default ValidatorDrawer;
