import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from '../axios';

const initialState = {
   transactions : [],
   selectedTransaction: null
};

export const getTransactions = createAsyncThunk('Transaction/getTransactions', async () => {
    try {
        const response = await axios.get(`/transaction`);
        return response.data;
    } catch (error) {
        const customError = {
            name: error.code,
            message: error.message,
        };
        throw customError;
    }
});

export const getFilteredTransactions = createAsyncThunk('Transaction/getFilteredTransactions', async (data) => {
    try {
        const response = await axios.patch(`/transaction/filter`, data);
        return response.data;
    } catch (error) {
        const customError = {
            name: error.code,
            message: error.message,
        };
        throw customError;
    }
});

export const getTransactionById = createAsyncThunk('Transaction/getTransactionById', async (id) => {
    // try {
    //     const response = await axios.get(`transaction/${id}`);
    //     return response.data;
    // } catch (error) {
    //     const customError = {
    //         name: error.code,
    //         message: error.message,
    //     };
    //     throw customError;
    // }
});

export const transactionSlice = createSlice({
	name: 'Transaction',
	initialState,
	reducers: {},
	extraReducers: {
       [getTransactions.fulfilled]: (state, action) => {
           state.transactions = action.payload;
       },
       [getFilteredTransactions.fulfilled]: (state, action) => {
        state.transactions = action.payload;
       },  
    },
});

export default transactionSlice.reducer