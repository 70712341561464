import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { style } from "../../Styles";
import CustomTableComponents from "../CommonComponents/CustomTableComponents";
import ActionButtonWithChangeStatus from "../CommonComponents/ActionButtonWithChangeStatus";
import { Breadcrumb, Button, Card, message } from "antd";
import { ReloadOutlined, MinusOutlined } from "@ant-design/icons";
import Header from "../../global/Header/Header";
import { changeCardTypeStatus, deleteCardType, getCardTypes } from "../../redux/cardTypeSlice";
import CardTypeDrawer from "./CardTypeDrawer";
import { useTranslation } from "react-i18next";

const CardTypes = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("cardTypeScreen.table.name"),
      key: "name",
      render: (record) => {
        return <span>{record.name}</span>;
      },
      sorter: (a, b) => a.name.length - b.name.length,
      show: true,
    },
    {
      title: t("cardTypeScreen.table.description"),
      key: "description",
      render: (record) => {
        return record.description ? (
          <span>{record.description}</span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => a.description.length - b.description.length,
      show: true,
    },
    {
      title: t("cardTypeScreen.table.status"),
      key: "active",
      render: (record) => {
        return (
          <span style={style.dataContainer}>
            {record.active ? (
              <div style={style.statusActif}>ACTIVE</div>
            ) : (
              <div style={style.statusInactif}>INACTIVE</div>
            )}
          </span>
        );
      },
      sorter: (a, b) => a.active.length - b.active.length,
      show: true,
    },
    {
        title: t("cardTypeScreen.table.actions"),
        key: "actions",
        render: (text, record) => (
          <ActionButtonWithChangeStatus
            record={record}
            onDelete={(record) => handleDeleteCardType(record)}
            onRowClick={() => handleSelectedRow(record)}
            onChangeStatus={() => handleChangeStatus(record)}
          />
        ),
  
        show: true,
    }
  ];

  const [filter, setFilter] = useState("");
  const [cardType, setCardType] = useState(undefined);
  const [isMakingApiCall, setMakingApiCall] = useState(true);

  const dispatch = useDispatch();
  const { cardTypes } = useSelector((state) => state.cardTypes);

  const [isCardTypeDrawerOpen, setCardTypeDrawerOpen] = useState(false);

  const fetchCardTypes = () => {
    dispatch(getCardTypes())
      .unwrap()
      .then(() =>setMakingApiCall(false))
      .catch((err) =>{
        console.error(err)
        setMakingApiCall(false)});
  };

  useEffect(() => {
    fetchCardTypes();
  }, [dispatch]);

  const filtredData = cardTypes?.filter((item) => {
    return (
      item?.name
        .toString()
        .toLowerCase()
        ?.includes(filter.toLowerCase()) ||
      item?.description.toString().toLowerCase().includes(filter.toLowerCase()) ||
      item?.active.toString().toLowerCase()?.includes(filter.toLowerCase())
    );
  });

  const handleSelectedRow = (record) => {
    setCardType(record);
    showDrawer();
  };
  const showDrawer = () => {
    setCardTypeDrawerOpen(true);
  };

  const onDrawerClose = () => {
    setCardTypeDrawerOpen(false);
    setCardType(undefined);
  };

  const handleDeleteCardType = (id) => {
    dispatch(deleteCardType(id))
      .unwrap()
      .then(() => {
        message.success(t("cardTypeScreen.messages.CardTypeDeletedSuccessfully"));
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  const handleChangeStatus = (record) => {
    dispatch(changeCardTypeStatus({ id: record.id, active: !record.active }))
      .unwrap()
      .then(() => {
        message.success(t("cardTypeScreen.messages.changeStatusSuccessfully"));
      })
      .catch((e) => {
        message.error(e.message);
      });
  };
  const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;

  return (
    <>
      <Header
        addButtonText={t("cardTypeScreen.header.addCardType")}
        onAddButtonClick={() => showDrawer()}
        onSearch={(e) => {
          setFilter(e.target.value);
        }}
        searchPlaceholder={t("cardTypeScreen.header.search")}
        addButtonBackgroundColor={primaryColor} // #ecefff
        addButtonBorderColor={primaryColor}
        addButtonTextColor={"#ffffff"}
        onReload={fetchCardTypes}
        showAddButton={true}
        BreadcrumbItems={[
          {
            title: t("cardTypeScreen.header.title"),
          },
        ]}
        showBreadcrumb={true}
      />
      <Card style={{ margin: 16, height: "85vh" }}>
        <CustomTableComponents
          isMakingApiCall={isMakingApiCall}
          columns={columns.filter((column) => column.show)}
          dataArray={filtredData}
          handleDoubleClickRow={(record) => handleSelectedRow(record)}
          handleSelectedRow={() => {}}
        />
      </Card>
      {isCardTypeDrawerOpen && <CardTypeDrawer onClose={onDrawerClose} cardTypeId={cardType?.id} />}
    </>
  );
};

export default CardTypes;
