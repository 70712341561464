import { Button, Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { MoreOutlined } from "@ant-design/icons";
import ModalGoogleMaps from "./Modals/ModalGoogleMaps";
import { useDispatch } from "react-redux";
import { changeKioskStatus } from "../redux/kiosksSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import KioskDrawer from "../components/Kiosks/KioskDrawer";

function ActionMenu({ record }) {
  const { t } = useTranslation();
  const [menuVisible, setMenuVisible] = useState(false);
  const [isModalLocationOpen, setIsModalLocationOpen] = useState(false);
  const [modalKioskOpened, setModalKioskOpened] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const handleOpenLocation = () => {
    setIsModalLocationOpen(true);
  };
  const handleCloseLocation = () => {
    setIsModalLocationOpen(false);
  };

  const changeStatusOfTheKiosk = () => {
    dispatch(
      changeKioskStatus({
        id: record.id,
        active: !record.active,
      })
    );
  };

  const editKioskName = () => {
    setModalKioskOpened(true)
  }
  const onCloseModal = () => {
    setModalKioskOpened(false);
  };

  const handleMenuClick = ({ key }) => {
    // switch case depending on the key
    switch (key) {
      case "Edit kiosk":
        editKioskName();
        break;
      case "Activate":
        changeStatusOfTheKiosk();
        break;
      case "Roll Replacement":{
        const filterValue = { printer: record?.hardware?.find((item) => item.name == "PRINTER" ), kiosk: record}
        navigate("/app/main/logs/hardware-logs", { state: { filterFromKiosk: filterValue } })
        break;
      }
      case "Maintenance":{
        const filterValueMaintenance = { id: record?.id}
        navigate("/app/main/logs/maintenance-logs", { state: { filterFromKiosk: filterValueMaintenance } })
        break;
      }
      case "Replenishements":{
        const filterValueMaintenance = { id: record?.id, maintenanceType: "TOPUP_CARD"}
        navigate("/app/main/logs/maintenance-logs", { state: { filterFromKiosk: filterValueMaintenance } })
        break;
      }
      case "Card Sales":{
        const filterValueMaintenance = { transactionType: "New card",  kiosk: record?.id}
        navigate("/app/main/transactions", { state: { filterFromKiosk: filterValueMaintenance } })
        break;
      }
      case "Card Top Ups":{
        const filterValueMaintenance = { transactionType: "Top up card",  kiosk: record?.id}
        navigate("/app/main/transactions", { state: { filterFromKiosk: filterValueMaintenance } })
        break;
      }
      case "Ticket Sales":{
        const filterValueMaintenance = { transactionType: "Ticket",  kiosk: record?.id}
        navigate("/app/main/transactions", { state: { filterFromKiosk: filterValueMaintenance } })
        break;
      }
      case "Show Location":
        handleOpenLocation();
        break;
      default:
        break;
    }
    setMenuVisible(false);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="Edit kiosk">
        {t("kioskScreen.kioskCard.actionMenu.editKiosk")}
      </Menu.Item>
      <Menu.Item key="Activate">
        {record?.active
          ? t("kioskScreen.kioskCard.actionMenu.deactivate")
          : t("kioskScreen.kioskCard.actionMenu.activate")}
      </Menu.Item>
      {record?.type != "PORTABLE_KIOSK" && <Menu.Item key="Roll Replacement">
        {t("kioskScreen.kioskCard.actionMenu.rollReplacement")}
      </Menu.Item>}
      {record?.type != "PORTABLE_KIOSK" && <Menu.Item key="Maintenance">
        {t("kioskScreen.kioskCard.actionMenu.maintenance")}
      </Menu.Item>}
      {record?.type != "PORTABLE_KIOSK" && <Menu.Item key="Replenishements">

        {t("kioskScreen.kioskCard.actionMenu.replenishments")}
      </Menu.Item>}
      <Menu.Item key="Card Sales">
        {t("kioskScreen.kioskCard.actionMenu.cardSales")}
      </Menu.Item>
      <Menu.Item key="Card Top Ups">
        {t("kioskScreen.kioskCard.actionMenu.cardTopUps")}
      </Menu.Item>
      <Menu.Item key="Ticket Sales">
      {t("kioskScreen.kioskCard.actionMenu.ticketSales")}
      </Menu.Item>
      <Menu.Item key="Show Location">
        {t("kioskScreen.kioskCard.actionMenu.showLocation")}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        visible={menuVisible}
        onVisibleChange={(visible) => {
          setMenuVisible(visible);
        }}
      >
        <Button
          style={{
            backgroundColor: "#e3e9fa",
            border: "none",
            borderRadius: "30%",
          }}
          color="#c2c8d1"
          type="text"
          icon={<MoreOutlined />}
          onClick={(event) => event.stopPropagation()}
        />
      </Dropdown>
      {isModalLocationOpen && (
        <ModalGoogleMaps
          kioskName={record?.name}
          lat={record?.position?.latitude}
          lng={record?.position?.longitude}
          setIsMapModalOpened={handleCloseLocation}
        />
      )}

      {modalKioskOpened && (
        <KioskDrawer kioskId={record.id} onClose={onCloseModal} />
      )}
    </>
  );
}

export default ActionMenu;
